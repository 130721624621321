<div class="container-fluid">
    <div class="row product-adding">
        <div class="col-xl">
            <div class="card">
                <div class="card-header">
                    <h5>General</h5>
                    <button class="btn btn-primary me-1 mb-3" style="float: right;"(click)="updateProducto()">Guardar</button>
                </div>                
                <div class="card-body">
                    <div class="digital-add needs-validation">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="col-form-label pt-0"><span>*</span> Nombre</label>
                                    <input class="form-control" type="text" required="" [(ngModel)]="nombre">
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label"><span>*</span>Categorias</label>
                                    <select class="custom-select form-select" (change)="onChangeCategoria($event.target.value)" required="" [(ngModel)]="categoriaSeleccionada">
                                        <option value="">--Select--</option>
                                        <option *ngFor="let cat of categorias" [value]="cat.id">{{ cat.nombre }}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label pt-0"><span>*</span> Familia</label>
                                    <input class="form-control" type="text" required="" [(ngModel)]="familia">
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label pt-0"><span>*</span> Subfamilia</label>
                                    <input class="form-control" type="text" required="" [(ngModel)]="subfamilia">
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label">Descripcion</label>
                                    <textarea rows="5" cols="12" [(ngModel)]="descripcion"></textarea>
                                </div>
                                <label class="col-form-label pt-0"> Foto del producto</label>
                                <div class="card-body dropzone-custom p-0">
                                    <ngx-dropzone class="dropzone-border" (change)="onSelect($event)">
                                        <ngx-dropzone-label>
                                            <div class="dz-message needsclick">
                                                <i class="fa fa-cloud-upload"></i>
                                                <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                            </div>
                                        </ngx-dropzone-label>
                                        <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                        </ngx-dropzone-preview>
                                    </ngx-dropzone>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="col-form-label"><span>*</span> Precio</label>
                                    <input class="form-control" type="text" required="" [(ngModel)]="precio">
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label"><span>*</span> Precio oferta</label>
                                    <input class="form-control" type="text" required="" [(ngModel)]="precio_oferta">
                                </div>
                                <div class="form-group row">
                                    <div class="col-xl-3 col-md-4">
                                        <label>Oferta</label>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="checkbox checkbox-primary">
                                            <input id="checkbox-primary-2"  type="checkbox" data-original-title="" title="" [(ngModel)]="oferta">
                                            <label for="checkbox-primary-2">Activar oferta</label>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label>Visible</label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="checkbox checkbox-primary">
                                                <input id="checkbox-visible" type="checkbox" [(ngModel)]="visible">
                                                <label for="checkbox-visible">Producto visible en la app</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label>Destacar</label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="checkbox checkbox-primary">
                                                <input id="checkbox-destacado" type="checkbox" [(ngModel)]="destacado">
                                                <label for="checkbox-destacado">Elegir como destacado</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 
