import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard'; 

export const content: Routes = [
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
    data: {
      breadcrumb: "Inicio"
    }
  },
  {
    path: 'products',
    loadChildren: () => import('../../components/products/products.module').then(m => m.ProductsModule),
    data: {
      breadcrumb: "Productos"
    }
  },
  {
    path: 'sales',
    loadChildren: () => import('../../components/sales/sales.module').then(m => m.SalesModule),
    data: {
      breadcrumb: "Usuarios"
    }
  },
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: "Lista de empleados"
    }
  },
  {
    path: 'verpedido',
    loadChildren: () => import('../../components/verpedido/verpedido.module').then(m => m.PedidosModule),
    data: {
      breadcrumb: "Ver Pedido"
    }
  },
  {
    path: 'verproducto',
    loadChildren: () => import('../../components/verproducto/verproducto.module').then(m => m.VerProductoModule),
    data: {
      breadcrumb: "Ver Producto"
    }
  },
  {
    path: 'coupons',
    loadChildren: () => import('../../components/coupons/coupons.module').then(m => m.CouponsModule),
    data: {
      breadcrumb: "Cupones"
    }
  },
  // {
  //   path: 'pages',
  //   loadChildren: () => import('../../components/pages/pages.module').then(m => m.PagesModule),
  //   data: {
  //     breadcrumb: "Pages"
  //   }
  // },


];