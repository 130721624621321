import { DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { SortEvent } from 'src/app/shared/directives/shorting.directive';
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
import { TableService } from 'src/app/shared/service/table.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-digital-sub-category',
  templateUrl: './digital-sub-category.component.html',
  styleUrls: ['./digital-sub-category.component.scss'],
  providers: [TableService, DecimalPipe],
})

export class DigitalSubCategoryComponent implements OnInit {
  categoriaFiltro:any=''
  datos:any=[];
  datosFiltrados:any=[]
  estadoFiltro: string = '';
  provincia: string;
  ingredienteSeleccionado: any;
  user_rol: string;
  constructor(public service: TableService, private modalService: NgbModal,private https: HttpClient, private router: Router,private cdr: ChangeDetectorRef) {


  }


  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    console.log("ddsds");

    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  async ngOnInit() { 
    var t = localStorage.getItem('auth_app_token');
    this.provincia = 'ejido'
    this.user_rol = localStorage.getItem('user_rol');
    if(this.user_rol === 'ejido' || this.user_rol === 'admin'){
      await this.obtenerDatos({token:t});
    }
  }

  async obtenerDatos(body:any): Promise<void> {

    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': 'Bearer '+body.token
      }),
      params:new HttpParams().set('provincia', this.provincia),
    };

    return new Promise((resolve, reject) => {
      this.https.get("https://localhost:3000/ingredientes", httpOptions).subscribe(
        info => {
          this.datos = info;
          console.log(info);
          console.log(this.datos);
          this.cdr.markForCheck();
          resolve();
        },
        err => {
          console.log(err);
          reject();
        }
      );
      this.cdr.markForCheck();
    });

  }

 


  abrirModalUsuario(content, producto?: any) {
    //this.categoriasSeleccionadas = this.datosCategorias
    this.ingredienteSeleccionado = {...producto} || {} as any;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      if (result) {
        if (this.ingredienteSeleccionado.id) { 
          console.log(this.ingredienteSeleccionado)
        //  this.editarProducto(this.ingredienteSeleccionado);
        } else {
          this.crearProducto(this.ingredienteSeleccionado);
        }
      }
    }, (reason) => { });
  }


  crearProducto(ingrediente:any){
    
    console.log(ingrediente);
    const body={
      nombre: ingrediente.nombre,
      descripcion: ingrediente.descripcion,
      producto: ingrediente.producto,
      tipo: ingrediente.tipo
    }
    var t = localStorage.getItem('auth_app_token');
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': 'Bearer '+t
      }),
      params:new HttpParams().set('provincia', this.provincia),
    };

    this.https.post("https://localhost:3000/ingredientes",body, httpOptions).subscribe(
      info => {
        console.log(info)
        location.reload();
      },
      async err => {
        console.log(err);
      }
    );
  }

  async eliminarIngrediente(ingrediente:any): Promise<void>{
    var t = localStorage.getItem('auth_app_token');
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': 'Bearer '+t
      }),
      params:new HttpParams().set('provincia', this.provincia),
    };

    return new Promise((resolve, reject) => {
      this.https.delete("https://localhost:3000/ingredientes/"+ingrediente.id, httpOptions).subscribe(
        info => {
          console.log(info);
          location.reload();
          resolve();
        },
        err => {
    
          reject();
        }
      );
      this.cdr.markForCheck();
    });
  }

}
