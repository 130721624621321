import { DecimalPipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';
import { UsuariosPanelService } from 'src/app/shared/service/usuarios_panel.service';
import { UserListDB, USERLISTDB } from 'src/app/shared/tables/list-users';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-list-panel-users',
  templateUrl: './list-panel-users.component.html',
  styleUrls: ['./list-panel-users.component.scss'],
  providers: [TableService, DecimalPipe]
})
export class ListPanelUsersComponent {
  @ViewChild('editModal') editModal: any;
  public selectedUser: any;
  public user_list = []
  public tableItem$: Observable<UserListDB[]>;
  public searchText;
  total: any;
  page = 1;
  pageSize = 20;
  paginatedUsuarios: any;
  usuarios: any[] = [];
  filteredUsuarios: any[] = [];
  filterName: string = '';
  selectedLocal: string = '';
  public editForm: FormGroup;
  

  constructor(
    public service: TableService,
    public usuariosPanelService: UsuariosPanelService,
    private modalService: NgbModal,
    private fb: FormBuilder

    
    ) {
    this.tableItem$ = service.tableItem$;
    this.service.setUserData(USERLISTDB)
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  ngOnInit() {
    this.getUsuarios();
    this.initEditForm();
  }

  initEditForm() {
    this.editForm = this.fb.group({
      nombre: ['', [Validators.required]],
      apellidos: ['', [Validators.required]],
      email: ['', [Validators.required]],
      telefono: ['', [Validators.required]],
      local: ['', [Validators.required]],
      activo: [true, [Validators.required]]
    });
  }

  openEditModal(usuario) {
    this.selectedUser = Object.assign({}, usuario);
    console.log(this.selectedUser.id)
    this.editForm.patchValue({
      nombre: this.selectedUser.nombre,
      apellidos: this.selectedUser.apellidos,
      email: this.selectedUser.email,
      telefono: this.selectedUser.telefono,
      local: this.selectedUser.local,
      activo: this.selectedUser.activo

    });
    this.modalService.open(this.editModal);
    console.log('Editando usuario con ID:', this.selectedUser.id); 
  }

  dismissAllModals() {
    this.modalService.dismissAll();
  }
  updateUser() {
    if (this.editForm.valid) {
        const updatedInfo = {
            id: this.selectedUser.id,
            nombre: this.editForm.value.nombre,
            apellidos: this.editForm.value.apellidos,
            email: this.editForm.value.email,
            telefono: this.editForm.value.telefono,
            local: this.editForm.value.local,
            activo: this.editForm.value.activo
        };

        this.usuariosPanelService.updateUsuario(updatedInfo).subscribe(
            response => {
                console.log('Usuario actualizado', response);
                this.modalService.dismissAll(); // Cerrar el modal después de la actualización
                this.getUsuarios(); // Recargar la lista de usuarios

                // Mostrar mensaje de éxito con SweetAlert
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Usuario editado con éxito.',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK'
                });
            },
            error => {
                console.error('Error al actualizar el usuario:', error);
                // Mostrar mensaje de error con SweetAlert
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No se pudo actualizar el usuario.',
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Cerrar'
                });
            }
        );
    } else {
        console.error('El formulario no es válido');
        // Opcional: informar al usuario que el formulario no es válido
        Swal.fire({
            icon: 'error',
            title: 'Formulario inválido',
            text: 'Por favor, verifica los datos e intenta nuevamente.',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Cerrar'
        });
    }
}


  getUsuarios(){
    this.usuariosPanelService.getAllUsuarios().subscribe(
      res => {
        this.usuarios = res;
        console.log(this.usuarios);
        this.total = this.usuarios.length;
        this.filterUsers();
        this.paginateEvents();
      }, 
      error => {
        console.error('Error al traer los usuarios:', error);
      }
    )
  }
  filterUsers() {
    let tempUsuarios = this.usuarios;

    // Filter by local if a local is selected
    if (this.selectedLocal) {
      tempUsuarios = tempUsuarios.filter(user => user.local === this.selectedLocal);
    }

    // Filter by name if there is a name entered in the filter
    if (this.filterName) {
      tempUsuarios = tempUsuarios.filter(user => 
        user.nombre.toLowerCase().includes(this.filterName.toLowerCase()) || user.email.toLowerCase().includes(this.filterName.toLowerCase()));
    }

    this.filteredUsuarios = tempUsuarios;
  }

  deleteUsuario(id: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar!'
    }).then((result) => {
      if (result.value) {
        this.usuariosPanelService.deleteUsuario(id).subscribe(
          () => {
            console.log('Usuario eliminado correctamente');
            this.getUsuarios();
          },
          (error) => {
            console.error('Error al eliminar el usuario:', error);
          }
        );
      }
    });
  }
paginateEvents() {
  const startIndex = (this.page - 1) * this.pageSize;
  const endIndex = startIndex + this.pageSize;
  console.log(this.page)
}

onPageChange(newPage: number) {
  this.service.page = newPage;
  this.paginateEvents();
}
}
