import { Injectable } from '@angular/core';
import { BASE_URL_API } from './../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Categoria } from '../model/category.model';
@Injectable({
  providedIn: 'root'
})
export class CategoriasService {
  constructor(private http: HttpClient) { }

  private getHeaders(): HttpHeaders {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const authUserItem = localStorage.getItem('auth_user');
  
    if (authUserItem) {
      const token = JSON.parse(authUserItem).token;
      if (token) {
        headers = headers.set('Authorization', `Bearer ${token}`);
      }
    }
    
    return headers;
  }
  
  getAllCategorias(): Observable<Categoria[]> {
    return this.http.get<Categoria[]>(`${BASE_URL_API}/categorias`, { headers: this.getHeaders() })
      .pipe(catchError(this.handleError));
  }
  
  getCategoriaById(id: number): Observable<Categoria> {
    return this.http.get<Categoria>(`${BASE_URL_API}/categorias/${id}`, { headers: this.getHeaders() })
      .pipe(catchError(this.handleError));
  }
  
  addCategoria(categoriaData: any): Observable<Categoria> {
    console.log(categoriaData)
    return this.http.post<Categoria>(`${BASE_URL_API}/categorias`, categoriaData, { headers: this.getHeaders() })
      .pipe(catchError(this.handleError));
  }
  
  updateCategoria(id: number, categoriaData: any): Observable<Categoria> {
    return this.http.put<Categoria>(`${BASE_URL_API}/categorias/${id}`, categoriaData, { headers: this.getHeaders() })
      .pipe(catchError(this.handleError));
  }
  
  deleteCategoria(id: number): Observable<any> {
    return this.http.delete<any>(`${BASE_URL_API}/categorias/${id}`, { headers: this.getHeaders() })
      .pipe(catchError(this.handleError));
  }
  
  private handleError(error: HttpErrorResponse) {
    console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
    return throwError('Algo malo ocurrió; por favor, inténtalo de nuevo más tarde.');
  }
}
