import { UsuarioPanel } from '../model/usuario_panel.model';
import { Injectable } from '@angular/core';
import { BASE_URL_API } from '../../../environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UsuariosPanelService {
  private isLoggedInSubject = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this.isLoggedInSubject.asObservable();

constructor(
  private http: HttpClient,
  private router: Router
  ) { }

private getHeaders(): HttpHeaders {
  let headers = new HttpHeaders().set('Content-Type', 'application/json');
  const authUserItem = localStorage.getItem('auth_app_token');

  if (authUserItem) {
    const token = JSON.parse(authUserItem).token;
    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);

    }
  }


  return headers;
}
register(data: any): Observable<UsuarioPanel> {
  return this.http.post<UsuarioPanel>(`${BASE_URL_API}/usuarios_panel/register-panel`, data)
    .pipe(
      tap(response => console.log('Registro exitoso', response)),
      catchError(this.handleError)
    );
}

getAllUsuarios(): Observable<UsuarioPanel[]> {
  return this.http.get<UsuarioPanel[]>(`${BASE_URL_API}/usuarios_panel/usuarios`, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

updateUsuario(usuario: any): Observable<any> {
  return this.http.put(`${BASE_URL_API}/usuarios_panel/${usuario.id}`, usuario, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}


login(loginData: any): Observable<any> {
  return this.http.post<any>(`${BASE_URL_API}/login/user`, loginData)
    .pipe(
      tap(response => {
        console.log('Login exitoso', response);
        this.isLoggedInSubject.next(true); // Actualiza el estado de autenticación
        // Aquí también deberías manejar el almacenamiento de tokens o datos del usuario si es necesario
      }),
      catchError(this.handleError)
    );
}

logout(): void {
  localStorage.removeItem('auth_user');// Asumiendo que guardas el token aquí
  localStorage.clear();
  this.isLoggedInSubject.next(false); // Actualiza el estado de autenticación
  this.router.navigate(['sign-in']); // Redirige al usuario a la página de inicio de sesión
}

isAuthenticated(): boolean {
  return this.isLoggedInSubject.value;
}


deleteUsuario(id:number): Observable<UsuarioPanel[]> {
  return this.http.delete<UsuarioPanel[]>(`${BASE_URL_API}/usuarios_panel/${id}`, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

private handleError(error: HttpErrorResponse) {
  console.log(`Backend returned code ${error.status}, body was: `, error.message);
  let errorMessage = error.message;
  return throwError(errorMessage);
}








}
