<div *ngIf="user_rol === 'ejido' || user_rol === 'admin'" class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Ingredientes</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between mb-3">
                        <div>
                          <button type="button" class="btn btn-primary" (click)="abrirModalUsuario(modalUsuario)">
                            AÑADIR INGREDIENTE
                          </button>
                        </div>
                      </div>
  
                    <div id="batchDelete" class="custom-datatable">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <th scope="col" sortable="order_id" (sort)="onSort($event)">ID</th>
                                    <th scope="col" sortable="product" (sort)="onSort($event)">Nombre</th>
                                    <th scope="col" sortable="payment_status" (sort)="onSort($event)">Descripcion</th>
                                    <th scope="col" sortable="paymeny_method" (sort)="onSort($event)">Tipo</th>
                                    <th scope="col" sortable="image" (sort)="onSort($event)">Producto</th>
                                  </tr>
                                </thead>
                                <tbody >
                                <tr *ngFor="let item of datos">
                                  
                                  <th scope="row">{{ item.id}}</th>
                                  
                                  <td>{{ item.nombre }}</td>
                                  <td>{{ item.descripcion }}</td>
                                  <td>{{item.tipo}}</td>
                                  <td>{{item.producto}}</td>
                                 
                                    <td>
                                        <a  (click)="eliminarIngrediente(item)" ><i class='fa fa-trash'></i></a>&nbsp;
                                    </td>
                                    
                                </tr>                              
                                </tbody>
                  
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
 
  <!-- Modal para crear/editar usuario -->
<ng-template #modalUsuario let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ingredienteSeleccionado.usuario_id ? 'Editar  Producto' : 'Añadir Producto'}}</h4>
        <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
  
            <div class="form-group" style="margin-bottom: 1rem;">
                <label for="nombre">Nombre:</label>
                <input type="text" class="form-control" id="nombre" [(ngModel)]="ingredienteSeleccionado.nombre" name="nombre" maxlength="100">
            </div>
  
            <div class="form-group" style="margin-bottom: 1rem;">
                <label for="correo">Descripcion:</label>
                <input type="email" class="form-control" id="correo" [(ngModel)]="ingredienteSeleccionado.descripcion" name="correo">
            </div>
  
            <div class="form-group" style="margin-bottom: 1rem;">
                <label for="producto">Producto:</label>
                <select class="form-control" id="producto" name="producto" [(ngModel)]="ingredienteSeleccionado.producto">
                  <option [value]="'hamburguesa'">Hamburguesa</option>
                  <option [value]="'poke'">Poke</option>
                </select>
            </div>
  
            <div class="form-group" style="margin-bottom: 1rem;" *ngIf="ingredienteSeleccionado.producto">
                <label for="tipo">Tipo:</label>
                <select class="form-control" id="tipo" name="tipo" [(ngModel)]="ingredienteSeleccionado.tipo">
                  <option  *ngIf="ingredienteSeleccionado.producto ==='poke'" [value]="'Bases'">Bases</option>
                  <option *ngIf="ingredienteSeleccionado.producto ==='poke'" [value]="'Salsas'">Salsas</option>
                  <option *ngIf="ingredienteSeleccionado.producto ==='poke'" [value]="'Proteina'">Proteina</option>
                  <option [value]="'Toppins'">Toppins</option>
                  <option *ngIf="ingredienteSeleccionado.producto ==='poke'" [value]="'Complementos'">Complementos</option>
                  <option *ngIf="ingredienteSeleccionado.producto ==='hamburguesa'" [value]="'Carnes'">Carnes</option>
                </select>
            </div>
        
  
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" [disabled]="!ingredienteSeleccionado.nombre" (click)="modal.close('Save click')">Guardar</button>
    </div>
  </ng-template>
  