import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: '/dashboard/default', title: 'Inicio', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Categorias', icon: 'layers', type: 'sub', active: false, children: [
				{ path: '/products/categorias', title: 'Categorias', type: 'link' }
			]
		},
		{
			title: 'Productos', icon: 'box', type: 'sub', active: false, children: [
				{
					title: 'Productos', type: 'link', path: '/products/digital/digital-product-list' 
				},
				{
					title: 'Añadir Productos', type: 'link', path: '/products/digital/digital-add-product'
				},
			]
		},
		{
			title: 'Cupones', icon: 'percent', type: 'sub', active: false, children: [
				{
					title: 'Lista Cupones', type: 'link', path: '/coupons/list-coupon' 
				},
				{
					title: 'Añadir Cupones', type: 'link', path: '/coupons/create-coupon'
				},
			]
		},
		{
			title: 'Usuarios', icon: 'users', type: 'sub', active: false, children: [
				{ path: '/sales/orders', title: 'Historial de Usuarios', type: 'link' },
				{ path: '/users/list-user', title: 'Usuarios', type: 'link' },
			]
		},
		{
			title: 'Empleados', icon: 'user', type: 'sub', active: false, children: [
				{ path: '/users/list-panel-users', title: 'Lista de Empleados', type: 'link' },
				{ path: '/users/create-user', title: 'Añadir Empleado', type: 'link' }
			]
		}
	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
