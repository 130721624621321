import { Messages } from './../model/chat.model';
import { Injectable } from '@angular/core';
import { BASE_URL_API } from './../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Producto } from '../model/product.model';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductosService {

constructor(private http: HttpClient) { }

private getHeaders(): HttpHeaders {
  let headers = new HttpHeaders().set('Content-Type', 'application/json');
  const authUserItem = localStorage.getItem('auth_user');

  if (authUserItem) {
    const token = JSON.parse(authUserItem).token;
    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }
  }
  
  return headers;
}

getAllProductos(): Observable<Producto[]> {
  return this.http.get<Producto[]>(`${BASE_URL_API}/productos`, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

getAllProductosOferta(): Observable<Producto[]> {
  return this.http.get<Producto[]>(`${BASE_URL_API}/ofertas`, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

getProductoById(id: number): Observable<Producto> {
  return this.http.get<Producto>(`${BASE_URL_API}/productos/${id}`, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

getProductoCategoria(id: number): Observable<any> {
  return this.http.get<any>(`${BASE_URL_API}/productos_categorias/${id}`, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}
getCategoriaProducto(id: number): Observable<any> {
  // Asegúrate de que la URL apunte al endpoint correcto '/categoria/:id'
  return this.http.get<any>(`${BASE_URL_API}/productos_categorias/categoria/${id}`, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

addProducto(productoData: any): Observable<any> {
  return this.http.post<any>(`${BASE_URL_API}/productos`, productoData, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

addProductoExel(productoData: any): Observable<any> {
  return this.http.post<any>(`${BASE_URL_API}/productos/excel`, productoData, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

updateProducto(id: number, productoData: any): Observable<Producto> {
  return this.http.put<Producto>(`${BASE_URL_API}/productos/${id}`, productoData, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

updateVisible(id: number, body: any): Observable<Producto> {
  return this.http.put<Producto>(`${BASE_URL_API}/productos/visible/${id}`, body, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

deleteProducto(id: number): Observable<any> {
  return this.http.delete<any>(`${BASE_URL_API}/productos/${id}`, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}
uploadImage(file: File): Observable<any> {
  const formData = new FormData();
  formData.append('imagen', file); // Asegúrate de que 'image' coincida con el nombre esperado en el backend

  // No establezcas Content-Type para este request; el navegador lo hará automáticamente
  return this.http.post(`${BASE_URL_API}/subir_archivo`, formData).pipe(
    catchError(this.handleError)
  );
}


private handleError(error: HttpErrorResponse) {
  console.error(`Backend returned code ${error.status}, body was: ${error.error.message}`);
  let errorMessage = error.error.message;
  console.log(error);
  return throwError(errorMessage);
}
}
