<!-- Container-fluid starts-->
<div class="container-fluid" *ngIf="couponForm">
    <div class="row product-adding">
        <div class="col-xl">
            <div class="card">
                <div class="card-header">
                    <h5>Crear Cupón</h5>
                </div>
                <div class="card-body">
                    <form [formGroup]="couponForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label for="categoria">Seleccione una Categoría</label>
                            <select class="form-control" id="categoria" (change)="onChangeCategoria($event.target.value)" required>
                                <option value=""> Seleccionar </option>
                                <option *ngFor="let categoria of categorias" [value]="categoria.id">
                                    {{ categoria.nombre }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="producto">Seleccione un Producto</label>
                            <select class="form-control" id="producto" (change)="onChangeProductoSeleccionado($event.target.value)" formControlName="producto" required>
                                <option value=""> Seleccionar </option>
                                <option *ngFor="let producto of productos" [value]="producto.id">
                                    {{ producto.nombre }}
                                </option>
                            </select>
                            <div *ngIf="couponForm.get('producto').touched && couponForm.get('producto').errors?.required">
                                Este campo es obligatorio.
                            </div>
                        </div>
                        <div *ngIf="precioProducto">
                            <p>Precio del Producto: {{ precioProducto}}€</p>
                          </div>
                        <div class="form-group">
                            <label for="detalles">Descripción</label>
                            <textarea class="form-control" id="detalles" formControlName="detalles" rows="5" required></textarea>
                            <div *ngIf="couponForm.get('detalles').touched && couponForm.get('detalles').errors?.required">
                                Este campo es obligatorio.
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="puntos_necesarios">Puntos Necesarios</label>
                            <input type="number" class="form-control" id="puntos_necesarios" formControlName="puntos_necesarios" required>
                            <div *ngIf="couponForm.get('puntos_necesarios').touched && couponForm.get('puntos_necesarios').errors?.required">
                                Este campo es obligatorio.
                            </div>
                            <div *ngIf="couponForm.get('puntos_necesarios').touched && couponForm.get('puntos_necesarios').errors?.min">
                                El valor debe ser mayor que 0.
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" formControlName="activo"> Activar Cupón
                                </label>
                            </div>
                        </div>

                        <div class="form-group">
                            <button type="button" (click)="onSubmit()" class="btn btn-primary">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
