import { AuthService } from './../../../shared/service/auth.service';
import { UsuariosPanelService } from 'src/app/shared/service/usuarios_panel.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, NgModule, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsModule, FormControl } from '@angular/forms';
import { AuthUser, LoginRequest } from 'src/app/shared/model/auth.model';
import { Router } from '@angular/router';
import { Toast } from 'bootstrap';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  myGroup: FormGroup;
  public loginForm: FormGroup;

  public active = 1;
  public email:any='';
  public pass:any='';
  terminos:boolean=false;
  alerta = false;
  msgError = ''
  mostrarAnimacionLogo:boolean=false;

  constructor(
    private cdr: ChangeDetectorRef,
    private https: HttpClient,
    private router: Router,
    private fb: FormBuilder,
    private usuariosPanelService: UsuariosPanelService,
    private authService: AuthService
    ) { }
    

  owlcarousel = [
    {
      title: "¡Bienvenido a La Yaya heladeria!",
      desc: "Llega La Yaya con ganas de cuidarte como te mereces. Una heladería artesana que no pasa desapercibida y volverás por su calidad y servicios. Trabajamos con productos de primera porque nos gusta lo bueno y eso se nota en el resultado final."
    }
  ]
  
  owlcarouselOptions = {
    loop: false,
    items: 1,
  };



  ngOnInit() {
    console.log()
  }


  onSubmit() {
    const loginData: LoginRequest = {
      email: this.email,
      pass: this.pass,
    };
  
    this.authService.login(loginData)
      .subscribe({
        next: (response) => {
          const authUser: AuthUser = {
            token: response.token,
            nombre: response.userDetails.nombre,
            email: response.userDetails.email,
            id: response.userDetails.id
          };
          console.log(authUser);
          localStorage.setItem("auth_app_token", JSON.stringify(authUser));
          this.router.navigate(['dashboard/default']);
        },
        error: (errorResponse) => {
          // Accessing error details correctly
          console.log('Error response', errorResponse);
          const errorMessage = errorResponse.error || 'Error al iniciar sesión. Intente de nuevo.';
          console.log('Error message', errorMessage);
          this.msgError = errorMessage;
          this.mostrarAlerta();
        }
      });
  }
cerrarAlerta() {
  this.alerta = false;
}

mostrarAlerta() {
  this.alerta = true;
  setTimeout(() => {
    this.cerrarAlerta();
  }, 2000);
}
  

  aceptarTerminos(){
    if(this.terminos==false){
      this.terminos=true
    }else{
      this.terminos=false
    }
    console.log(this.terminos)
  }

}
