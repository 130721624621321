
<div class="container-fluid">
    <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
            <h5>Detalles de los empleados</h5>
            <div class="form-inline">
                <input type="text" class="form-control mr-2" style="width: 200px; margin-bottom: 10px;" placeholder="Buscar por nombre..." [(ngModel)]="filterName" (ngModelChange)="filterUsers()">
                <select class="form-control" style="width: 200px;" [(ngModel)]="selectedLocal" (ngModelChange)="filterUsers()">
                    <option value="">Todos los locales</option>
                    <option value="Almerimar">Almerimar</option>
                    <option value="Adra">Adra</option>
                    <option value="Balerma">Balerma</option>
                </select>
            </div>
        </div>
        
        
        <div class="card-body">
            <div class="btn-popup pull-right">
                <a class="btn btn-primary" [routerLink]="'/users/create-user'">Crear Empleado</a>
            </div>
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col" sortable="fName">Nombre</th>
                                <th scope="col" sortable="lName">Apellidos</th>
                                <th scope="col" sortable="email">Email</th>
                                <th scope="col" sortable="last_login">Teléfono</th>
                                <th scope="col" sortable="role">Local</th>
                                <th scope="col" sortable="role">Activo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let usuario of filteredUsuarios">
                                <td>
                                    {{usuario.nombre}}
                                </td>
                                <td>{{usuario.apellidos}}</td>
                                <td>{{usuario.email}}</td>
                                <td>{{usuario.telefono}}</td>
                                <td>{{usuario.local}}</td>
                                <td style="text-align: center;">  <i class="fa fa-circle" [ngClass]="{ 'estado-activo': usuario.activo, 'estado-inactivo': !usuario.activo }" f-12></i></td>
                                <td>
                                    <a href="javascript:void(0)" (click)="openEditModal(usuario)"><i class='fa fa-edit f-12'></i></a>&nbsp;
                                    <a href="javascript:void(0)" (click)="deleteUsuario(usuario.id)"><i class="fa fa-trash-o"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                    <ng-template #editModal>
                        <div class="modal-header">
                            <h5 class="modal-title">Editar Usuario</h5>
                            <button type="button" class="close" aria-label="Close" (click)="dismissAllModals()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="editForm">
                                <div class="form-group">
                                    <label for="userName">Nombre</label>
                                    <input type="text" class="form-control" id="userName" formControlName="nombre" [disabled]="true">
                                </div>
                                <div class="form-group">
                                    <label for="userLastName">Apellidos</label>
                                    <input type="text" class="form-control" id="userLastName" formControlName="apellidos" [disabled]="true">
                                </div>
                                <div class="form-group">
                                    <label for="userLastName">Email</label>
                                    <input type="text" class="form-control" id="email" formControlName="email" [disabled]="true">
                                </div>
                                <div class="form-group">
                                    <label for="userPhone">Teléfono</label>
                                    <input type="text" class="form-control" id="userPhone" formControlName="telefono">
                                </div>

                                <div class="form-group">
                                    <label for="userLocal">Local</label>
                                    <select class="form-control" id="userLocal" formControlName="local">
                                        <option value="Almerimar">Almerimar</option>
                                        <option value="Adra">Adra</option>
                                        <option value="Balerma">Balerma</option>
                                    </select>
                                </div>
                                <div class="form-group mt-3 d-flex ">
                                    <input type="checkbox" id="activo" formControlName="activo" class="form-check-input">
                                    <label for="activo">Empleado activo en la app</label>
                                  </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" (click)="dismissAllModals()">Cerrar</button>
                            <button type="button" class="btn btn-primary" (click)="updateUser()">Guardar Cambios</button>
                        </div>
                    </ng-template>
                    <!-- <div class="d-flex justify-content-center p-2">
                        <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="pageSize" (pageChange)="onPageChange($event)">
                            <ng-template ngbPaginationNumber let-page>
                                <span [ngClass]="{ 'page-item-selected': page === service.page, 'page-item-unselected': page !== service.page }">{{ page }}</span>
                            </ng-template>
                        </ngb-pagination>
                    </div> -->

            </div>
        </div>
    </div>
</div>

