import { DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { SortEvent } from 'src/app/shared/directives/shorting.directive';
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
import { TableService } from 'src/app/shared/service/table.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
  providers: [TableService, DecimalPipe],
})

export class TransactionsComponent implements OnInit {

  categoriaFiltro:any=''
  datos:any=[];
  datosUsuario:any=[];
  datosFiltrados:any=[]
  datosCategorias:any=[];
  estadoFiltro: string = '';
  user_rol: string;
  constructor(public service: TableService, private modalService: NgbModal,private https: HttpClient, private router: Router,private cdr: ChangeDetectorRef) {


  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    console.log("ddsds");

    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  async ngOnInit() { 
    var t = localStorage.getItem('auth_app_token');

    this.user_rol = localStorage.getItem('user_rol');
    if(this.user_rol === 'ejido' || this.user_rol === 'admin'){
      await this.obtenerDatos({token:t});
    }

  }

  async obtenerDatos(body:any): Promise<void> {

    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': 'Bearer '+body.token
      }),
      params:new HttpParams().set('provincia', 'ejido'),
    };

    return new Promise((resolve, reject) => {
      this.https.get("https://localhost:3000/pedidos", httpOptions).subscribe(
        info => {
          this.datos = info;
          console.log(info);
          console.log(this.datos);
          this.datos = this.datos.reverse();
          this.cdr.markForCheck();
          resolve();
        },
        err => {
          console.log(err);
          reject();
        }
      );
      this.cdr.markForCheck();
    });

  }

  verPedido(event){
    console.log(event)
    localStorage.setItem('provincia', 'ejido');
    localStorage.setItem('pedido_seleccionado',  event.id);
    this.router.navigate(['./verpedido'])
  }

  aplicarFiltro() {
      // Filtrar los datos según el estado seleccionado
    if (this.estadoFiltro === '') {
      // No hay filtro, muestra todos los pedidos
      this.datosFiltrados = this.datos;
    } else {
      // Aplica el filtro según el estado
      this.datosFiltrados = this.datos.filter(item => item.estado === this.estadoFiltro);
    }
  }

}
