import { DecimalPipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';
import { UsuariosService } from 'src/app/shared/service/usuarios.service';
import { UserListDB, USERLISTDB } from 'src/app/shared/tables/list-users';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss'],
  providers: [TableService, DecimalPipe]
})
export class ListUserComponent implements OnInit {
  public user_list = []

  public tableItem$: Observable<UserListDB[]>;
  public searchText;
  usuarios: any;
  total: any;
  page = 1;
  pageSize = 20;
  filterText: string = '';
  paginatedUsuarios: any;
  filteredUsuarios: any[] = [];
  

  constructor(
    public service: TableService,
    public usuariosService: UsuariosService
    
    ) {
    this.tableItem$ = service.tableItem$;
    this.service.setUserData(USERLISTDB)
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  ngOnInit() {
    this.getUsuarios();
  }

  getUsuarios(){
    this.usuariosService.getAllUsuarios().subscribe(
      res => {
        this.usuarios = res;
        console.log(this.usuarios);
        this.total = this.usuarios.length;
        this.filterUsers();
        this.paginateEvents();
      }, 
      error => {
        console.error('Error al traer los usuarios:', error);
      }
    )
  }
  filterUsers() {
    // Resetear a todos los usuarios si el filtro está vacío
    if (!this.filterText) {
      this.filteredUsuarios = this.usuarios;
    } else {
      this.filteredUsuarios = this.usuarios.filter(u => u.email.toLowerCase().includes(this.filterText.toLowerCase()) || u.nombre.toLowerCase().includes(this.filterText.toLowerCase()));
    }
    this.paginateEvents();
  }

  paginateEvents() {
    const startIndex = (this.page - 1) * this.pageSize;
    this.paginatedUsuarios = this.filteredUsuarios.slice(startIndex, startIndex + this.pageSize);
  }


onPageChange(newPage: number) {
  this.service.page = newPage;
  this.paginateEvents();
}
}

