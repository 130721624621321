
<div class="container-fluid">
    <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
            <h5>Lista de categorias</h5>
            <div class="form-inline">
                <input type="text" class="form-control mr-2" style="width: 200px; margin-bottom: 10px;" placeholder="Buscar por nombre..." [(ngModel)]="filterName" (ngModelChange)="filterCategorias()">
            </div>
        </div>
        
        
        <div class="card-body">
            <div class="btn-popup pull-right">
                <a class="btn btn-primary" (click)="openCreateCategoryModal()">Crear Categoría</a>
            </div>
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col" sortable="fName">Nombre</th>
                                <th scope="col" sortable="lName">Id</th>
                                <th scope="col" sortable="status">Visible</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let categoria of filteredCategorias">
                                <td>
                                    {{categoria.nombre}}
                                </td>
                                <td>{{categoria.id}}</td>
                                <td>
                                    <i class="fa fa-circle" [ngClass]="{ 'estado-activo': categoria.visible, 'estado-inactivo': !categoria.visible }" f-12></i>
                                </td>
                                <td>
                                    <a href="javascript:void(0)" (click)="openEditModal(categoria)"><i class='fa fa-edit f-12'></i></a>&nbsp;
                                    <a href="javascript:void(0)" (click)="deleteCategoria(categoria.id)"><i class="fa fa-trash-o"></i></a>
                                </td>
                                <ng-template #editModal>
                                    <div class="modal-header">
                                        <h5 class="modal-title">Editar Categoría</h5>
                                        <button type="button" class="close" aria-label="Close" (click)="dismissAllModals()">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <form [formGroup]="editForm">
                                            <div class="form-group">
                                                <label for="userName">Nombre</label>
                                                <input type="text" class="form-control" id="userName" formControlName="nombre" [disabled]="true">
                                            </div>
                                            <div class="form-group mt-3 d-flex ">
                                                <input type="checkbox" id="visible" formControlName="visible" class="form-check-input">
                                                <label for="visible">Categoría visible en la app</label>
                                              </div>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" (click)="dismissAllModals()">Cerrar</button>
                                        <button type="button" class="btn btn-primary" (click)="updateUser()">Guardar Cambios</button>
                                    </div>
                                </ng-template>
                                
                            </tr>
                        </tbody>
                    </table> 
                    <!-- <div class="d-flex justify-content-center p-2">
                        <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="pageSize" (pageChange)="onPageChange($event)">
                            <ng-template ngbPaginationNumber let-page>
                                <span [ngClass]="{ 'page-item-selected': page === service.page, 'page-item-unselected': page !== service.page }">{{ page }}</span>
                            </ng-template>
                        </ngb-pagination>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

