import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CategoriasService } from 'src/app/shared/service/categorias.service';
import { ProductosService } from 'src/app/shared/service/productos.service';
import { json } from 'stream/consumers';
import { Router } from '@angular/router';
import { BASE_URL_API } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import ImageCompressor from 'image-compressor.js';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})
export class EditProductComponent implements OnInit {
  public Editor = ClassicEditor;
  nombre: any;
  descripcion: any;
  categoriaSeleccionada: any;
  categoria:any;
  precio:any;
  imagen: any;
  oferta: any;
  precio_oferta: any;
  en_oferta:any;
  categorias:any;
  producto:any
  categoria_id: any;
  activo:any;
  visible: boolean = true;
  destacado: boolean = false;
  familia: any;
  subfamilia: any;
  
  constructor(
    private categoriasService: CategoriasService,
    private productosService: ProductosService,
    private router: Router,
    private http: HttpClient
  ) { }

  files: File[] = [];

  onSelect(event): void {
    const file = event.addedFiles[0]; // Tomar solo el primer archivo añadido
    const acceptedFileTypes = ['image/jpeg', 'image/png'];

    if (file && acceptedFileTypes.includes(file.type)) {
      this.compressImage(file);
    } else {
      console.error('Tipo de archivo no permitido.');
    }
  }
  compressImage(file: File): void {
    new ImageCompressor(file, {
        quality: 0.8, // Ajusta la calidad para la compresión
        maxWidth: 1920, // Máximo ancho de la imagen
        maxHeight: 1080, // Máximo alto de la imagen
        success: (compressedFile) => {
            const fileSizeInKB = compressedFile.size / 1024; // Convertir bytes a kilobytes
            console.log(`Tamaño del archivo comprimido: ${fileSizeInKB.toFixed(2)} KB`);

            // Crear el archivo final sin revisar el tamaño
            const finalFile = new File([compressedFile], file.name, {
                type: compressedFile.type,
                lastModified: Date.now()
            });
            this.files = [finalFile]; // Almacenar el archivo final
            console.log(this.files);
            this.uploadFile(finalFile); // Llamar al método para subir el archivo
        },
        error: (e) => {
            console.error('Error durante la compresión de la imagen', e);
        },
    });
}



  uploadFile(file: File): void {
    const formData = new FormData();
    formData.append('image', file, file.name); // Usar 'image' como clave para el FormData

    this.http.post(`${BASE_URL_API}/productos/subir_archivo`, formData).subscribe({
        next: (response: any) => {
            console.log('Archivo subido con éxito', response);
            this.imagen = `${BASE_URL_API}/dist/upload/${response.filename}`; // Actualizar el enlace de la imagen en la aplicación
            console.log(this.imagen);
        },
        error: (error) => {
            console.error('Error al subir el archivo', error);
        }
    });
}

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  ngOnInit() {
    this.getAllCategorias()
    this.producto =JSON.parse(localStorage.getItem('productoSeleccionado'));
    console.log(this.producto)
    this.nombre= this.producto.nombre;
    this.descripcion= this.producto.descripcion;
    this.precio= this.producto.precio;
    this.imagen = this.producto.imagen;
    this.precio_oferta = this.producto.precio_oferta;
    this.oferta= this.producto.en_oferta;
    this.categoria= this.categoriaSeleccionada;
    this.visible = this.producto.visible;
    this.destacado = this.producto.destacado;
    this.familia = this.producto.familia;
    this.subfamilia = this.producto.subfamilia;
    this.getCategoria();
    console.log(this.destacado)
  }

  
  onChangeCategoria(newValue: string) {
    this.categoriaSeleccionada = Number(newValue);
    console.log(this.categoriaSeleccionada);
}
  getCategoria() {
    if (this.producto && this.producto.id) {
      this.productosService.getCategoriaProducto(this.producto.id).subscribe({
        next: (categorias) => {
          if (categorias && categorias.length > 0) {
            // Asume que la primera categoría es la relevante
            this.categoriaSeleccionada = categorias[0].id;
            // Para mostrar el nombre, guarda el objeto de la categoría
            this.categoria = categorias[0];
          }
        },
        error: (error) => {
          console.error('Error al cargar la categoría:', error);
        }
      });
    }
  }


  updateProducto(){
   let productoData = {
    id_empresa: 1,
    imagen: this.imagen,
    codigo: 4214,
    nombre : this.nombre,
    categoria_id: this.categoriaSeleccionada,
    precio:this.precio,
    precio_oferta:this.precio_oferta,
    descripcion: this.descripcion,
    en_oferta: +this.oferta,
    visible: +this.visible,
    destacado : +this.destacado, 
    familia: this.familia,
    subfamilia:this.subfamilia,
    }
    console.log(productoData);
    this.productosService.updateProducto(this.producto.id, productoData ).subscribe(
      response => {
        console.log('producto actualizado correctamente')
        this.goToList();
      }, 
      error=> {
        console.log('error')
      }
    )
  }

  getAllCategorias(){
    this.categoriasService.getAllCategorias(). subscribe(
      res => {
        this.categorias = res;
        //console.log(this.categorias);
      }, 
      error => {
        console.error('Error al añadir worker:', error);
      }
    )
  }

  goToList(){
    this.router.navigate(['products/digital/digital-product-list']);
  }

}
