<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
            <h5>Detalles del usuario</h5>
            <input type="text" [(ngModel)]="filterText" class="form-control w-25" placeholder="Buscar usuario..." (ngModelChange)="filterUsers()">
        </div>
        <div class="card-body">
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col" sortable="fName">Nombre</th>
                                <th scope="col" sortable="lName">Apellidos</th>
                                <th scope="col" sortable="email">Email</th>
                                <th scope="col" sortable="last_login">Teléfono</th>
                                <th scope="col" sortable="role">Puntos</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let usuario of filteredUsuarios">
                                <td>
                                    {{usuario.nombre}}
                                </td>
                                <td>{{usuario.apellidos}}</td>
                                <td>{{usuario.email}}</td>
                                <td>{{usuario.telefono}}</td>
                                <td>{{usuario.puntos}}</td>
                            </tr>
                        </tbody>
                    </table> 
                    <!-- <div class="d-flex justify-content-center p-2">
                        <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="pageSize" (pageChange)="onPageChange($event)">
                            <ng-template ngbPaginationNumber let-page>
                                <span [ngClass]="{ 'page-item-selected': page === service.page, 'page-item-unselected': page !== service.page }">{{ page }}</span>
                            </ng-template>
                        </ngb-pagination>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->