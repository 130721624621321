import { CreatyCategoryComponent } from './creaty-category/creaty-category.component';
import { CategoriasService } from './../../../../shared/service/categorias.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { SortEvent } from 'src/app/shared/directives/shorting.directive';
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
import { TableService } from 'src/app/shared/service/table.service';
import { DigitalCategoryDB } from 'src/app/shared/tables/digital-category';
import { DIGITALLIST, DigitalListDB } from 'src/app/shared/tables/digital-list';
import { ProductosService } from 'src/app/shared/service/productos.service';
import * as XLSX from 'xlsx'
import Swal from 'sweetalert2';
@Component({
  selector: 'app-digital-list',
  templateUrl: './digital-list.component.html',
  styleUrls: ['./digital-list.component.scss'],
  providers: [TableService, DecimalPipe],
})
export class DigitalListComponent implements OnInit {
  tableItem$: Observable<DigitalListDB[]>;
  productos: any
  ofertas: any;
  categoriaSeleccionada: any;
  producto: any;
  public digital_categories = []
  total: any;
  page = 1;
  pageSize = 5;
  paginatedProductos: any;
  filterText: string = '';
  filteredProducts: any[] = [];
  categorias: any;
  activeTab: string = 'todos';
  categoryName: any;
  msgError: any;
  constructor(private productosService: ProductosService,
    public service: TableService,
    private modalService: NgbModal,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private categoriasService: CategoriasService,
    private cdr: ChangeDetectorRef,
  ) {
    this.tableItem$ = service.tableItem$;
    this.service.setUserData(DIGITALLIST)
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {

    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  async ngOnInit() {
    this.getProductos();

  }

  async getProductos() {
    this.productosService.getAllProductos().subscribe({
      next: async res => {
        this.productos = res;
        this.total = this.productos.length;
        // Actualizar cada producto con su categoría
        this.productos.forEach(producto => {
          this.getCategoria(producto.id, (categoriaNombre) => {
            producto.categoria = categoriaNombre; // Añadir la categoría al producto
          });
        });
        console.log(this.productos);

        this.filterProducts();
        await this.obtenerCategorias()
        this.paginateProductos();
      },
      error: error => {
        console.error('Error al encontrar los productos:', error);
      }
    });
  }


  async obtenerCategorias() {
    this.activeTab
    this.categoriasService.getAllCategorias().subscribe(
      info => {
        this.categorias = info;

      },
      err => {
        console.log(err);
      }
    );
    this.cdr.markForCheck();
  }

  getCategoria(id: number, callback: (categoria: string) => void) {
    this.productosService.getCategoriaProducto(id).subscribe({
      next: (categorias) => {
        if (categorias && categorias.length > 0) {
          callback(categorias[0].nombre); // Llama al callback con el nombre de la categoría
        }
      },
      error: (error) => {
        console.error('Error al cargar la categoría:', error);
        callback('Sin categoría'); // Gestiona el error con una categoría por defecto o manejo de error
      }
    });
  }

  selectCategory(categoryId: number | null) {
    this.msgError = ''
    if (categoryId === null) {
      this.activeTab = 'todos'
      this.getProductos(); // Un método para cargar todos los productos
    } else {
      this.activeTab = categoryId.toString();
      this.productosService.getProductoCategoria(categoryId).subscribe(
        productos => {
          this.filteredProducts = productos;
        },
        error => {
          this.filteredProducts = [];
          console.error('Error al cargar productos para la categoría:', error);
          const errorMessage = error || 'Error al encontrar productos. Intente de nuevo.';
          this.msgError = errorMessage;
          console.log(this.msgError);
        }
      );
    }
  }

  // En tu componente
  openCreateCategoryModal() {
    const modalRef = this.modalService.open(CreatyCategoryComponent);
    modalRef.result.then((result) => {
      if (result) {
        console.log('Categoría creada:', result);
        this.obtenerCategorias(); // Recargar la lista de categorías
      }
    }, (reason) => {
      console.log('Modal dismissed:', reason);
    });
  }

  generarExcel() {
    const workbook = XLSX.utils.book_new();
    // Elimina la propiedad 'id' de los objetos en 'datos'
    console.log(this.productos)
    var body = [
      {
        nombre: "",
        codigo: "",
        precio : "",
        descripcion: "",
        familia: "",
        subfamilia : "",
        categoria: "",
      }
    ]
    const datosSinId = body.map(dato => {
      return dato;
    });
    // Crea una hoja de cálculo y asigna al archivo
    const worksheet = XLSX.utils.json_to_sheet(datosSinId);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Datos');
    // Escribe el archivo al disco como 'datos.xlsx'
    XLSX.writeFile(workbook, 'Productos.xlsx');
  }

  toggleVisibility(producto: any) {
    console.log(producto)
    if (producto.visible == 1) {
      producto.visible = 0
    } else {
      producto.visible = 1
    }
    var body = {
      visible: producto.visible
    }
    // const productoActualizado = {...producto, visible: producto.visible}
    // console.log(productoActualizado)
    this.productosService.updateVisible(producto.id, body).subscribe({
      next: response => {
        console.log(producto.visible)
        console.log('Visibilidad actualizada', response);
        // Opcional: mostrar notificación de éxito
      },
      error: error => {
        console.log(producto.visible)
        console.error('Error al actualizar visibilidad:', error);
        // Opcional: mostrar notificación de error
      }
    });
  }




  filterProducts() {
    if (!this.productos) return;

    let tempProductos = this.productos;

    // Filtrar por texto de búsqueda si hay algo en filterText
    if (this.filterText) {
      tempProductos = tempProductos.filter(p =>
        p.nombre.toLowerCase().includes(this.filterText.toLowerCase()) || p.codigo.toLowerCase().includes(this.filterText.toLowerCase())
      );
    }

    // Si hay una categoría seleccionada, filtra por esa categoría
    if (this.categoriaSeleccionada) {
      tempProductos = tempProductos.filter(p => p.categoria_id === this.categoriaSeleccionada);
    } else {
      // Si no hay categoría seleccionada, muestra todos los productos que coincidan con la búsqueda
      this.filteredProducts = tempProductos;
      this.page = 1;
    }

    this.paginateProductos(); // Asegúrate de que la paginación también se actualiza
  }


  getOfertas() {
    this.msgError = ''
    this.productosService.getAllProductosOferta().subscribe(
      ofertas => {
        this.filteredProducts = ofertas;
        this.activeTab = 'ofertas'
      },
      error => {
        console.error('Error al cargar los productos en oferta:', error);
      }
    );
  }

  goToEdit(producto: any) {
    // Almacena el producto seleccionado en localStorage
    localStorage.setItem('productoSeleccionado', JSON.stringify(producto));
    // Redirige a la página de edición
    this.router.navigate(['products/digital/digital-product-list/edit']);
  }


  deleteProduct(id: number) {
    this.productosService.deleteProducto(id).subscribe(
      () => {
        console.log('Producto eliminado correctamente');
        this.getProductos();
        // Realiza cualquier otra acción necesaria después de eliminar el producto
      },
      (error) => {
        console.error('Error al eliminar el producto:', error);
        // Maneja el error de acuerdo a tus necesidades
      }
    )
  }

  withConfirmation(id: number) {
    Swal.fire({
      title: '¿Estás seguro de que quieres eliminar este producto?',
      text: "No podrás recuperarlo",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, quiero eliminarlo'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Eliminado!',
        )
        this.deleteProduct(id);
        this.getProductos();

      }
    })
  }

  paginateProductos() {
    // Calcular el índice inicial y final de las Productos paginadas
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = Math.min(startIndex + this.pageSize, this.total); // Asegurar que endIndex no exceda el total de Productos

    console.log("Índice inicial:", startIndex);
    console.log("Índice final:", endIndex);

    // Obtener las Productos paginadas del conjunto filtrado por evento
    this.paginatedProductos = this.filteredProducts.slice(startIndex, endIndex);
    console.log("Productos paginadas:", this.paginatedProductos);
  }

  onPageChange(newPage: number) {
    this.page = newPage;
    this.service.page = newPage;
    this.paginateProductos();
  }

  async importarExcel() {
    // Crear un elemento de entrada de archivo en el DOM.
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xlsx';

    // Asignar un controlador de eventos al cambio de archivos seleccionados.
    input.onchange = async (e: Event) => {
      try {
        const target = e.target as HTMLInputElement;
        const file = target.files?.[0];
        if (file) {
          // Crear un lector de archivos para procesar el archivo Excel.
          const reader = new FileReader();

          // Asignar un controlador de eventos al cargar el archivo.
          reader.onload = async (e) => {
            const data = e.target?.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(sheet);

            // Validar los datos del archivo Excel.
            const requiredFields = ['nombre', 'codigo', 'descripcion', 'familia', 'categoria'];
            const dataArray = [];

            for (const row of jsonData) {
              let rowIsValid = true;
              console.log(row);

              if (row.hasOwnProperty('id')) {
                break;
              }

              for (const field of requiredFields) {
                console.log(field);
                if (!row[field]) {
                  rowIsValid = false;
                  break;
                }
              }

              if (rowIsValid) {
                const dataObject: any = {
                  nombre: row['nombre'],
                  codigo: row['codigo'] || '',
                  precio: row['precio'] || 0,
                  descripcion: row['descripcion'] || '',
                  familia: row['familia'] || '',
                  subfamilia: row['subfamilia'] || '',
                  categoria: row['categoria'] || ''
                };
                dataArray.push(dataObject);
              }
            }

            // Verificar si hay datos válidos para agregar.
            if (dataArray.length > 0) {
              if (dataArray.length > 1000) {
                alert('No se pueden insertar más de 1000 clientes.');
                return;
              }

              // Agregar los clientes en orden secuencial utilizando un ciclo for.
              var t = localStorage.getItem('auth_app_token');
              for (let i = 0; i < dataArray.length; i++) {
                if (i >= 1000) {
                  break;
                }
                try {
                  // Llamar a la función para agregar un cliente (this.agregarUsuario) con los datos del archivo.
                  await this.addProducto({
                    nombre: dataArray[i].nombre,
                    codigo: dataArray[i].codigo,
                    precio: dataArray[i].precio,
                    descripcion: dataArray[i].descripcion,
                    familia: dataArray[i].familia,
                    subfamilia: dataArray[i].subfamilia,
                    categoria: dataArray[i].categoria,
                    id_empresa: 1
                  });
                } catch (error) {
                  console.error(`Error al agregar producto ${i + 1}:, error`);
                }
              }
              // location.reload();
            }
          };
          reader.readAsBinaryString(file);
        }
      } catch (error) {
        console.error('Ha ocurrido un error:', error);
      }

    };
    input.click();
  }

  addProducto(body: any) {
    console.log(body)
    // const valorConvertido: string = this.oferta ? "1" : "0";
    let producto = {
      id_empresa: body.id_empresa,
      codigo: body.codigo,
      nombre: body.nombre,
      nombre_categoria: body.categoria,
      precio: body.precio,
      descripcion: body.descripcion,
      familia: body.familia,
      subfamilia: body.subfamilia
    }
    console.log(producto)

    this.productosService.addProductoExel(producto).subscribe(
      response => {
        console.log('producto añadido correctamente');
        this.router.navigate(['products/digital/digital-product-list']);

      },
      error => {
        console.log('error')
      }
    )
  }


}
