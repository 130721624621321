
    <div class="dashboard-container">
      <!-- Tarjeta para Productos -->
      <div class="card" (click)="navigate('/products/digital/digital-product-list')">
        <div class="card-content">
          <h2>Productos</h2>
          <p>Administra tu catálogo de productos aquí.</p>
        </div>
      </div>
    
      <!-- Tarjeta para Cupones -->
      <div class="card" (click)="navigate('coupons/list-coupon')">
        <div class="card-content">
          <h2>Cupones</h2>
          <p>Consulta y gestiona tus cupones y promociones.</p>
        </div>
      </div>
    
      <!-- Tarjeta para Usuarios -->
      <div class="card" (click)="navigate('sales/orders')">
        <div class="card-content">
          <h2>Usuarios</h2>
          <p>Revisa la lista de usuarios y sus actividades.</p>
        </div>
      </div>
    </div>
 







 <!---EL EJido-->
 <!-- <div class="container-fluid" >
  <div class="row" *ngIf="showForm2">
      <div class="col-sm-12">
          <div class="card" *ngIf="datosPendientes && datosPendientes.length > 0">
              <div class="card-header">
                  <h5>Pedidos pendientes</h5>
              </div>
              <div class="card-body" > -->
                  <!-- <select id="filtroCategorias" class="form-control" [(ngModel)]="categoriaFiltro" (change)="filtro()">
                      <option value="">Todas las categorías</option>
                      <option *ngFor="let categoria of datosCategorias" [value]="categoria.id">{{ categoria.nombre }}</option>
                  </select> -->
                  <!-- <div id="batchDelete" class="custom-datatable">
                      <div class="table-responsive">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col" sortable="order_id" (sort)="onSort($event)">ID</th>
                              <th scope="col" sortable="product" (sort)="onSort($event)">Nombre</th>
                              <th scope="col" sortable="payment_status" (sort)="onSort($event)">Telefono</th>
                           
                              <th scope="col" sortable="order_status" (sort)="onSort($event)">Fecha</th>
                              <th scope="col" sortable="date" (sort)="onSort($event)">Lugar</th>
                              <th scope="col" sortable="status" (sort)="onSort($event)">Estado</th>
                              <th scope="col" sortable="total" (sort)="onSort($event)">Total</th>
                            </tr>
                          </thead>
                          <tbody >
                          <tr *ngFor="let item of datosPendientes">
                            
                            <th scope="row">{{ item.id}}</th>
                            <td>{{ item.name }}</td>
                            <td>{{ item.phone }}</td>
                     
                            <td>{{item.date |date:'dd/MM/yyyy HH:mm:ss'}}</td>
                            <td>{{ item.way }}</td>
                            <td><span [ngClass]="{'pendiente': item.estado === 'pendiente', 'proceso': item.estado === 'proceso', 'completado': item.estado === 'completado', 'cancelado': item.estado === 'cancelado'}"></span>{{ item.estado }}</td>
                            <td>{{item.total}}€</td>
                           
                              <td>
                                  <a (click)="verPedido(item)" ><i class='fa fa-eye'></i></a>&nbsp;
  
                              </td>
                              
                          </tr>                              
                          </tbody>
            
                      </table>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-sm-12" *ngIf="datosProceso && datosProceso.length > 0">
        <div class="card">
            <div class="card-header">
                <h5>Pedidos En Proceso</h5>
            </div>
            <div class="card-body" > -->
                <!-- <select id="filtroCategorias" class="form-control" [(ngModel)]="categoriaFiltro" (change)="filtro()">
                    <option value="">Todas las categorías</option>
                    <option *ngFor="let categoria of datosCategorias" [value]="categoria.id">{{ categoria.nombre }}</option>
                </select> -->
                <!-- <div id="batchDelete" class="custom-datatable">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col" sortable="order_id" (sort)="onSort($event)">ID</th>
                            <th scope="col" sortable="product" (sort)="onSort($event)">Nombre</th>
                            <th scope="col" sortable="payment_status" (sort)="onSort($event)">Telefono</th>
                           
                            <th scope="col" sortable="order_status" (sort)="onSort($event)">Fecha</th>
                            <th scope="col" sortable="date" (sort)="onSort($event)">Lugar</th>
                            <th scope="col" sortable="status" (sort)="onSort($event)">Estado</th>
                            <th scope="col" sortable="total" (sort)="onSort($event)">Total</th>
                          </tr>
                        </thead>
                        <tbody >
                        <tr *ngFor="let item of datosProceso">
                          
                          <th scope="row">{{ item.id}}</th>
                          <td>{{ item.name }}</td>
                          <td>{{ item.phone }}</td>
               
                          <td>{{item.date |date:'dd/MM/yyyy HH:mm:ss'}}</td>
                          <td>{{ item.way }}</td>
                          <td><span [ngClass]="{'pendiente': item.estado === 'pendiente', 'proceso': item.estado === 'proceso', 'completado': item.estado === 'completado', 'cancelado': item.estado === 'cancelado'}"></span>{{ item.estado }}</td>
                          <td>{{item.total}}€</td>
                         
                            <td>
                                <a (click)="verPedido(item)" ><i class='fa fa-eye'></i></a>&nbsp;

                            </td>
                            
                        </tr>                              
                        </tbody>
          
                    </table>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
    <div class="col-sm-12" *ngIf="ultimosPedidos && ultimosPedidos.length > 0">
      <div class="card">
          <div class="card-header">
              <h5>Ultimos Pedidos</h5>
          </div>
          <div class="card-body" > -->
              <!-- <select id="filtroCategorias" class="form-control" [(ngModel)]="categoriaFiltro" (change)="filtro()">
                  <option value="">Todas las categorías</option>
                  <option *ngFor="let categoria of datosCategorias" [value]="categoria.id">{{ categoria.nombre }}</option>
              </select> -->
              <!-- <div id="batchDelete" class="custom-datatable">
                  <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col" sortable="order_id" (sort)="onSort($event)">ID</th>
                          <th scope="col" sortable="product" (sort)="onSort($event)">Nombre</th>
                          <th scope="col" sortable="payment_status" (sort)="onSort($event)">Telefono</th>
                         
                          <th scope="col" sortable="order_status" (sort)="onSort($event)">Fecha</th>
                          <th scope="col" sortable="date" (sort)="onSort($event)">Lugar</th>
                          <th scope="col" sortable="status" (sort)="onSort($event)">Estado</th>
                          <th scope="col" sortable="total" (sort)="onSort($event)">Total</th>
                        </tr>
                      </thead>
                      <tbody >
                      <tr *ngFor="let item of ultimosPedidos.slice(0, 5)">
                        
                        <th scope="row">{{ item.id}}</th>
                        <td>{{ item.name }}</td>
                        <td>{{ item.phone }}</td>
             
                        <td>{{item.date |date:'dd/MM/yyyy HH:mm:ss'}}</td>
                        <td>{{ item.way }}</td>
                        <td><span [ngClass]="{'pendiente': item.estado === 'pendiente', 'proceso': item.estado === 'proceso', 'completado': item.estado === 'completado', 'cancelado': item.estado === 'cancelado'}"></span>{{ item.estado }}</td>
                        <td>{{item.total}}€</td>
                       
                          <td>
                              <a (click)="verPedido(item)" ><i class='fa fa-eye'></i></a>&nbsp;

                          </td>
                          
                      </tr>                              
                      </tbody>
        
                  </table>
                  </div>
              </div>
          </div>
      </div>

  </div>
</div> -->

     

  