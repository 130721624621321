<div *ngIf="user_rol === 'ejido'|| user_rol === 'admin'" class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Pedidos</h5>
                </div>
                <div class="card-body">
                  <div class="form-group">
                    <label for="filtroEstado">Filtrar por estado:</label>
                    <select id="filtroEstado" class="form-control" [(ngModel)]="estadoFiltro" (change)="aplicarFiltro()">
                      <option value="">Todos</option>
                      <option value="pendiente">Pendientes</option>
                      <option value="proceso">Proceso</option>
                      <option value="completado">Completados</option>
                    </select>
                  </div>
                    <div id="batchDelete" class="custom-datatable">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <th scope="col" sortable="order_id" (sort)="onSort($event)">ID</th>
                                    <th scope="col" sortable="product" (sort)="onSort($event)">Nombre</th>
                                    <th scope="col" sortable="payment_status" (sort)="onSort($event)">Telefono</th>
                                    <th scope="col" sortable="paymeny_method" (sort)="onSort($event)">Email</th>
                                    <th scope="col" sortable="order_status" (sort)="onSort($event)">Fecha</th>
                                    <th scope="col" sortable="date" (sort)="onSort($event)">Lugar</th>
                                    <th scope="col" sortable="status" (sort)="onSort($event)">Estado</th>
                                    <th scope="col" sortable="total" (sort)="onSort($event)">Total</th>
                                  </tr>
                                </thead>
                                <tbody >
                                <tr *ngFor="let item of datos">
                                  
                                  <th scope="row">{{ item.id}}</th>
                                  <td>{{ item.name }}</td>
                                  <td>{{ item.phone }}</td>
                                  <td>{{ item.email }}</td>
                                  <td>{{item.date |date:'dd/MM/yyyy HH:mm:ss'}}</td>
                                  <td>{{ item.way }}</td>
                                  <td><span [ngClass]="{'pendiente': item.estado === 'pendiente', 'proceso': item.estado === 'proceso', 'completado': item.estado === 'completado', 'cancelado': item.estado === 'cancelado'}"></span></td>
                                  <td>{{item.total}}€</td>
                                 
                                    <td>
                                        <a href="javascript:void(0)" (click)="verPedido(item)" ><i class='fa fa-eye'></i></a>&nbsp;
        
                                    </td>
                                    
                                </tr>                              
                                </tbody>
                  
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <!-- Modal -->
  <ng-template #confirmModalContent let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="confirmModalLabel">Confirmación</h5>
      <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <p>¿Estás seguro de que quieres guardar el curso?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.dismiss('cancel')">Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="modal.close('confirm')">Confirmar</button>
    </div>
  </ng-template>
  