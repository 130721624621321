import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// import { Ng2SmartTableModule } from 'ng2-smart-table';
// import { CKEditorModule } from 'ngx-ckeditor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProductosService } from 'src/app/shared/service/productos.service';
import { CategoriasService } from 'src/app/shared/service/categorias.service';
import { ProductsRoutingModule } from './products-routing.module';
import { DigitalCategoryComponent } from './digital/digital-category/digital-category.component';
import { DigitalSubCategoryComponent } from './digital/digital-sub-category/digital-sub-category.component';
import { DigitalListComponent } from './digital/digital-list/digital-list.component';
import { DigitalAddComponent } from './digital/digital-add/digital-add.component';
import { CategoriasComponent } from './categorias/categorias.component';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import 'hammerjs';
import 'mousetrap';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxDropzoneModule } from 'ngx-dropzone';
// search module
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SharedModule } from 'src/app/shared/shared.module';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { EditProductComponent } from './digital/digital-list/edit-product/edit-product.component';
import { ChatService } from 'src/app/shared/service/chat.service';
import { CreatyCategoryComponent } from './digital/digital-list/creaty-category/creaty-category.component';
// import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
// import {  } from '../../directives/shorting.directive/';


@NgModule({
  declarations: [DigitalCategoryComponent, DigitalSubCategoryComponent, DigitalListComponent, DigitalAddComponent, CategoriasComponent, EditProductComponent, CreatyCategoryComponent
  ],
  imports: [
    Ng2SearchPipeModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ProductsRoutingModule,
    NgbModule,
    GalleryModule,
    CKEditorModule,
    NgxDropzoneModule,
    SharedModule,
    AngularMultiSelectModule
  ],
  exports: [],
  bootstrap: [],
  providers: [
    NgbActiveModal,
    ProductosService,
    CategoriasService,
    ChatService
  ]
})
export class ProductsModule { }
