import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BASE_URL_API } from 'src/environments/environment'; 
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isLoggedInSubject = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this.isLoggedInSubject.asObservable();
  
  constructor(
    private http: HttpClient,
    private router: Router
    ) { }

  login(data: any): Observable<any> {
    return this.http.post<any>(`${BASE_URL_API}/login/user`, data)
      .pipe(
        tap(response => {
          console.log('Login exitoso', response);
          this.isLoggedInSubject.next(true); // Actualiza el estado de autenticación
          // Aquí también deberías manejar el almacenamiento de tokens o datos del usuario si es necesario
        }),
        catchError(this.handleError)
      );
  }
  register(data: any): Observable<any> {
    return this.http.post<any>(`${BASE_URL_API}/usuarios/register`, data)
      .pipe(
        tap(response => console.log('Registro exitoso', response)),
        catchError(this.handleError)
      );
  }

  logout() {
    console.log('Logging out...');
    localStorage.removeItem('auth_app_token');
    this.isLoggedInSubject.next(false);
    this.router.navigate(['/login']).then(() => {
      console.log('Redirection to login page completed.');
      // Aquí puedes agregar más lógica si es necesario después de la redirección
    });
  }

  

isAuthenticated(): boolean {
  const token = localStorage.getItem('auth_app_token');
  return !!token && this.isLoggedInSubject.value;
}


  private handleError(error: HttpErrorResponse) {
    console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
    let errorMessage = error.error;
    console.log(error);
    return throwError(errorMessage);
  }


}
