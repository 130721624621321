import { UsuarioHistorial } from '../model/usuario_historial.model';
import { UsuarioPanel } from '../model/usuario_panel.model';
import { Injectable } from '@angular/core';
import { BASE_URL_API } from './../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

constructor(private http: HttpClient) { }

private getHeaders(): HttpHeaders {
  let headers = new HttpHeaders().set('Content-Type', 'application/json');
  const authUserItem = localStorage.getItem('auth_user');

  if (authUserItem) {
    const token = JSON.parse(authUserItem).token;
    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }
  }
  
  return headers;
}
getAllUsuarios(): Observable<UsuarioPanel[]> {
  return this.http.get<UsuarioPanel[]>(`${BASE_URL_API}/usuarios/usuarios`, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

getAllUsuariosHistory(): Observable<UsuarioHistorial[]> {
  return this.http.get<UsuarioHistorial[]>(`${BASE_URL_API}/usuarios_historial`, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

deleteUsuario(id:number): Observable<UsuarioPanel[]> {
  return this.http.delete<UsuarioPanel[]>(`${BASE_URL_API}/usuarios/${id}`, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

private handleError(error: HttpErrorResponse) {
  console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
  return throwError('Algo malo ocurrió; por favor, inténtalo de nuevo más tarde.');
}








}
