<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h5>Productos</h5>
                        <input type="text" [(ngModel)]="filterText" class="form-control w-25" placeholder="Buscar producto..." (ngModelChange)="filterProducts()">
                        <div class="btn-plantilla d-flex">
                        <button class="btn btn-primary me-1 mb-3" style="float: right;" (click)="generarExcel()">Descargar plantilla</button>
                        <button class="btn btn-primary me-1 mb-3" style="float: right;" (click)="importarExcel()">Subir productos</button>
                        </div>
                    </div>
                <div class="card-body">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link" [class.active]="activeTab === 'todos'" (click)="selectCategory(null)">Todos</a>
                        </li>
                        <li class="nav-item" *ngFor="let category of categorias">
                            <a class="nav-link" [class.active]="activeTab === category.id.toString()" (click)="selectCategory(category.id)">{{ category.nombre }}</a>
                        </li>
                        <!-- Pestaña de ofertas -->
                        <li class="nav-item">
                            <a class="nav-link" [class.active]="activeTab === 'ofertas'" (click)="getOfertas()">Ofertas</a>
                        </li>
                    </ul>
                    <div *ngIf="msgError"  class="alert alert-danger">{{ msgError }}</div>
                    <div *ngIf="!msgError" id="batchDelete" class="custom-datatable">
                        <div class="table-responsive">
                            <table class="table table-striped" >
                                <thead>
                                <tr>
                                    <th scope="col">Codigo</th>
                                    <th scope="col" sortable="img">Image</th>
                                    <th scope="col" sortable="title">Nombre</th>
                                    <th scope="col" sortable="entry_type">Descripcion</th>
                                    <th scope="col" sortable="quantity">Precio</th>
                                    <th scope="col" sortable="action">En oferta</th>
                                    <th scope="col" sortable="action">Precio oferta</th>
                                    <th scope="col" sortable="status">Visible</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let producto of filteredProducts">
                                    <th scope="row">{{ producto.codigo }}</th>
                                    <td>
                                        <img  [src]="producto.imagen || 'assets/images/yaya.png'" class="imgTable" style="width: 20px">

                                    </td>
                                        <td>{{ producto.nombre }}</td>
                                    <td>{{ producto.descripcion }}</td>
                                    <td>{{ producto.precio}}</td>
                                    <td>  <i class="fa fa-circle" [ngClass]="{ 'estado-activo': producto.en_oferta, 'estado-inactivo': !producto.en_oferta }" f-12></i></td>
                                    <td>{{ producto.precio_oferta}}</td>
                                    <td>
                                        <i class="fa fa-circle" [ngClass]="{ 'estado-activo': producto.visible, 'estado-inactivo': !producto.visible }" f-12></i>
                                    </td>
                                    <td>
                                        <a href="javascript:void(0)" (click)="goToEdit(producto)"><i class='fa fa-edit f-12'></i></a>&nbsp;
                                        <a href="javascript:void(0)" (click)="withConfirmation(producto.id)"><i class="fa fa-trash-o"></i></a> 
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                              <!-- end chat-history-->

                            <!-- <div class="d-flex justify-content-center p-2">
                                <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="pageSize" (pageChange)="onPageChange($event)">
                                    <ng-template ngbPaginationNumber let-page>
                                        <span [ngClass]="{ 'page-item-selected': page === service.page, 'page-item-unselected': page !== service.page }">{{ page }}</span>
                                    </ng-template>
                                </ngb-pagination>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>