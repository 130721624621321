<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <h5>Lista de cupones</h5>
                    <input type="text" [(ngModel)]="filterText" class="form-control w-25" placeholder="Buscar cupon..." (ngModelChange)="filterCupones()">
                </div>
                <div class="card-body">
                    <div class="custom-datatable">
                        <button class="btn btn-primary me-1 mb-3" style="float: right;"[routerLink]="'/coupons/create-coupon'">Añadir Cupon</button>
                        <div class="clearfix"></div>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th style="text-align: center;" scope="col" sortable="title">Detalles</th>
                                    <th style="text-align: center;" scope="col" sortable="code">Codigo</th>
                                    <th style="text-align: center;" scope="col" sortable="code">Id</th>
                                    <th style="text-align: center;" scope="col" sortable="code">Contador</th>
                                    <th style="text-align: center;" scope="col" sortable="discount">Puntos</th>
                                    <th style="text-align: center;" scope="col" sortable="status">Estado</th>
                                    <th style="text-align: center;" scope="col" sortable="status">Activar Cupon</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let cupon of filteredCupones">
                                <td style="text-align: center;">{{cupon.detalles}}</td>
                                <td style="text-align: center;">{{cupon.codigo_cupon}}</td>
                                <td style="text-align: center;">{{cupon.id}}</td>
                                <td style="text-align: center;" >{{cupon.contador}}</td>
                                <td style="text-align: center;">{{cupon.puntos_necesarios}}</td>
                                <td style="text-align: center;">  <i class="fa fa-circle" [ngClass]="{ 'estado-activo': cupon.activo, 'estado-inactivo': !cupon.activo }" f-12></i></td>
                                <td style="text-align: center;">
                                    <input type="checkbox" [checked]="cupon.activo" (change)="toggleEstadoCupon(cupon)">
                                </td>
                                <td>
                                    <a href="javascript:void(0)" (click)="openEditModal(cupon)"><i class='fa fa-edit f-12'></i></a>&nbsp;
                                    <a href="javascript:void(0)" (click)="deleteCupon(cupon.id)"><i class="fa fa-trash-o"></i></a>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <ng-template #editModal>
                            <div class="modal-header">
                                <h5 class="modal-title">Editar Cupon</h5>
                                <button type="button" class="close" aria-label="Close" (click)="dismissAllModals()">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form [formGroup]="editForm">
                                    <div class="form-group">
                                        <label for="detalles">Detalles</label>
                                        <input type="text" class="form-control" id="detalles" formControlName="detalles" >
                                    </div>
                                    <div class="form-group">
                                        <label for="puntos_necesarios">Puntos necesarios</label>
                                        <input type="text" class="form-control" id="puntos_necesarios" formControlName="puntos_necesarios">
                                    </div>

                                    <div class="form-group mt-3 d-flex ">
                                        <input type="checkbox" id="activo" formControlName="activo" class="form-check-input">
                                        <label for="activo">Cupón activo en la app</label>
                                      </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" (click)="dismissAllModals()">Cerrar</button>
                                <button type="button" class="btn btn-primary" (click)="updateCupon()">Guardar Cambios</button>
                            </div>
                        </ng-template>

                        <!-- <div class="d-flex justify-content-center p-2">
                            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="pageSize" (pageChange)="onPageChange($event)">
                                <ng-template ngbPaginationNumber let-page>
                                    <span [ngClass]="{ 'page-item-selected': page === service.page, 'page-item-unselected': page !== service.page }">{{ page }}</span>
                                </ng-template>
                            </ngb-pagination>
                          </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->