import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UsuariosPanelService } from 'src/app/shared/service/usuarios_panel.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  public registerForm: FormGroup;
  public permissionForm: FormGroup;
  public active = 1;
  successMessage: any;
  errorMessage: any;
  

  constructor
  (
    private router: Router,
    private fb: FormBuilder,
    private usuariosPanelService: UsuariosPanelService
  ) 
  { }

  ngOnInit() {
    this.createRegisterForm()
  }


  createRegisterForm() {
    this.registerForm = this.fb.group({
      nombre: ['',[Validators.required, Validators.minLength(3)]],
      apellidos: ['',[Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      telefono: ['', [Validators.required, Validators.minLength(9), Validators.pattern("^[0-9]*$")]],
      pass: ['', [Validators.required, Validators.minLength(6)]],
      local: ['', [Validators.required]] 
    });
  }

  addNewUser(): void {
    if (this.registerForm.valid) {
      let usuario = {
        nombre: this.registerForm.get('nombre')?.value,
        apellidos: this.registerForm.get('apellidos')?.value,
        email: this.registerForm.get('email')?.value,
        telefono: this.registerForm.get('telefono')?.value,
        pass: this.registerForm.get('pass')?.value,
        local: this.registerForm.get('local')?.value,
        id_empresa: 1  // Asegúrate de que este valor es el correcto para tu lógica de negocio
      };
      console.log(usuario)
      this.usuariosPanelService.register(usuario).subscribe({
        next: (response) => {
          Swal.fire({
            icon: 'success',
            title: '¡Registro exitoso!',
            text: 'Usuario registrado con éxito. ¡Bienvenido!',
            confirmButtonText: 'Aceptar'
          }).then((result) => {
            if (result.isConfirmed) {
              this.registerForm.reset(); // Opcional: Redirigir al usuario o realizar otras acciones
            }
          });
        },
        error: (error) => {
          // Asumiendo que el servidor devuelve un objeto de error que incluye una propiedad 'message'
          Swal.fire({
            icon: 'error',
            title: 'Error al registrar',
            text: error.error.message || 'No se pudo registrar el usuario.',
            confirmButtonText: 'Aceptar'
          });
        }
      });
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Datos incompletos',
        text: 'Por favor, asegúrate de que todos los campos estén llenos y sean válidos.',
        confirmButtonText: 'Aceptar'
      });
    }
  }
}



