<div class="modal-header">
    <h4 class="modal-title">Crear Nueva Categoría</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form>
        <div class="form-group">
            <label for="categoryName">Nombre de la Categoría:</label>
            <input id="categoryName" name="nombre" type="text" [(ngModel)]="categoryName" class="form-control" placeholder="Nombre de la categoría" name="categoryName">
        </div>
        <div class="col-md-7">
            <div class="checkbox checkbox-primary">
                <input id="checkbox-visible" type="checkbox" name="visible" [(ngModel)]="visible" checked>
                <label for="checkbox-visible">Categoría visible en la app</label>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="dismiss()">Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="save()">Guardar</button>
</div>
