import { Injectable } from '@angular/core';
import { BASE_URL_API } from './../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Cupon } from '../model/cupon.model';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CuponesService {

constructor(private http: HttpClient) { }

private getHeaders(): HttpHeaders {
  let headers = new HttpHeaders().set('Content-Type', 'application/json');
  const authUserItem = localStorage.getItem('auth_user');

  if (authUserItem) {
    const token = JSON.parse(authUserItem).token;
    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }
  }
  
  return headers;
}

getAllCupones(): Observable<Cupon[]> {
  return this.http.get<Cupon[]>(`${BASE_URL_API}/productos_cupones`, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

getCuponById(id: number): Observable<Cupon> {
  return this.http.get<Cupon>(`${BASE_URL_API}/productos_cupones/${id}`, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

getCuponCategoria(id: number): Observable<any> {
  return this.http.get<any>(`${BASE_URL_API}/productos_cupones_categorias/categoria/${id}`, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

addCupon(productoData: any): Observable<any> {
  console.log(productoData)
  return this.http.post<any>(`${BASE_URL_API}/productos_cupones`, productoData, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}
 
updateCupon(id: number, productoData: any): Observable<Cupon> {
  return this.http.put<Cupon>(`${BASE_URL_API}/productos_cupones/${id}`, productoData, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

deleteCupon(id: number): Observable<any> {
  return this.http.delete<any>(`${BASE_URL_API}/productos_cupones/${id}`, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

deleteCupones(ids: number[]): Observable<any> {
  return this.http.post<any>(`${BASE_URL_API}/productos_cupones/deleteBatch`, { ids }, { headers: this.getHeaders() })
    .pipe(catchError(this.handleError));
}

private handleError(error: HttpErrorResponse) {
  console.error(`Backend returned code ${error.status}, body was: ${error.message}`);
  let errorMessage = error.message;
  console.log(error);
  return throwError(errorMessage);
}

}
