import { DecimalPipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';
import { LISTCOUPLEDB, ListCouponsDB } from 'src/app/shared/tables/list-coupon';
import { CuponesService } from 'src/app/shared/service/cupones.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-list-coupon',
  templateUrl: './list-coupon.component.html',
  styleUrls: ['./list-coupon.component.scss'],
  providers: [TableService, DecimalPipe]
})
export class ListCouponComponent implements OnInit {
  @ViewChild('editModal') editModal: any;
  cupones: any;
  cupon: any;
  cuponesSeleccionados = [];

  public selected = [];
  
  public tableItem$: Observable<ListCouponsDB[]>;
  public searchText;
  total: any;
  page = 1;
  pageSize = 10;
  paginatedCupones: any;
  filterText: any;
  filteredCupones: any;
  editForm: FormGroup;
  selectedCupon: any;

  constructor(
    public service: TableService,
    private cuponesService: CuponesService,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder

    ) {
    this.tableItem$ = service.tableItem$;

  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSelect(cupon: any) {
    console.log(cupon);
    console.log(this.cuponesSeleccionados);
    if (cupon.seleccionado) {
      this.cuponesSeleccionados.push(cupon);
    } else {
      this.cuponesSeleccionados = this.cuponesSeleccionados.filter(c => c.id !== cupon.id);
    }
    console.log(this.cuponesSeleccionados)
  }

  ngOnInit() {
    this.getCupones();

   }

  getCupones(){
    this.cuponesService.getAllCupones().subscribe(
      res => {
        this.cupones = res;
        console.log(this.cupones);
        this.total = this.cupones.length;
        this.filterCupones();

      }, 
      error => {
        console.error('Error al traer los cupones:', error);
      }
    )
  }
  initEditForm() {
    this.editForm = this.fb.group({
      detalles: ['', [Validators.required]],
      puntos_necesarios: ['', [Validators.required]],
      puntos_requeridos: ['', [Validators.required]],
      activo: [true, [Validators.required]]
    });
  }

  openEditModal(cupon) {
    this.initEditForm();
    this.selectedCupon = Object.assign({}, cupon);
    console.log(this.selectedCupon.id)
    this.editForm.patchValue({
      detalles: this.selectedCupon.detalles,
      puntos_necesarios: this.selectedCupon.puntos_necesarios,
      puntos_requeridos: this.selectedCupon.puntos_requeridos,
      activo: this.selectedCupon.activo,
    });
    this.modalService.open(this.editModal);
    console.log('Editando cupon con ID:', this.selectedCupon.id); 
  }

  dismissAllModals() {
    this.modalService.dismissAll();
  }

  toggleEstadoCupon(cupon: any) {
    const estadoActualizado = { ...cupon, activo: cupon.activo ? 0 : 1 };
    this.cuponesService.updateCupon(cupon.id, estadoActualizado).subscribe(
      response => {
        console.log('Cupón actualizado:', response);
        this.getCupones(); // Recargar todos los cupones
      },
      error => {
        console.error('Error al actualizar el cupón:', error);
      }
    );
  }

  filterCupones() {
    if (!this.cupones) return;
    let tempCupones = this.cupones;
    // Filtrar por texto de búsqueda si hay algo en filterText
    if (this.filterText) {
      tempCupones = tempCupones.filter(c =>
        c.detalles.toLowerCase().includes(this.filterText.toLowerCase()) ||
        String(c.id).toLowerCase().includes(this.filterText.toLowerCase())
      );
      console.log(tempCupones)
    }
    this.filteredCupones = tempCupones;
    this.page = 1;
  }

  updateCupon() {
    if (this.editForm.valid) {
        const updatedInfo = {
            detalles: this.editForm.value.detalles,
            puntos_necesarios: this.editForm.value.puntos_necesarios,
            puntos_requeridos: this.editForm.value.puntos_necesarios,
            activo: this.editForm.value.activo,
        };
        console.log(this.selectedCupon.id)
        this.cuponesService.updateCupon(this.selectedCupon.id, updatedInfo).subscribe(
            response => {
                console.log('Cupon actualizado', response);
                this.modalService.dismissAll();
                this.getCupones(); 

                // Mostrar mensaje de éxito con SweetAlert
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Cupón editado con éxito.',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK'
                });
            },
            error => {
                console.error('Error al actualizar el cupón:', error);
                // Mostrar mensaje de error con SweetAlert
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No se pudo actualizar el cupón.',
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Cerrar'
                });
            }
        );
    } else {
        console.error('El formulario no es válido');
        // Opcional: informar al cupon que el formulario no es válido
        Swal.fire({
            icon: 'error',
            title: 'Formulario inválido',
            text: 'Por favor, verifica los datos e intenta nuevamente.',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Cerrar'
        });
    }
}
  
  deleteCupon(id:number){
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar!'
    }).then((result) => {
      if (result.value) {
        this.cuponesService.deleteCupon(id).subscribe(
          () => {
            console.log('Cupón eliminado correctamente');
            this.getCupones();
          },
          (error) => {
            console.error('Error al eliminar el cupón:', error);
          }
        );
      }
    });
  }

  paginateEvents() {
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedCupones = this.cupones.slice(startIndex, endIndex);
    console.log(this.paginatedCupones)
    console.log(this.page)
  }

  onPageChange(newPage: number) {
    this.service.page = newPage;
    this.paginateEvents();
  }

  onSort({ column, direction }) {
    // resetting other headers

    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }



}
