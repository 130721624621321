<div *ngIf="user_rol === 'ejido' || user_rol === 'admin'" class="container-fluid">
  <div class="row">
      <div class="col-sm-12">
          <div class="card">
              <div class="card-header">
                  <h5>Productos</h5>
              </div>
              <div class="card-body">
                  <div class="d-flex justify-content-between mb-3">
                      <div>
                        <button type="button" class="btn btn-primary" (click)="abrirModalUsuario(modalUsuario)">
                          AÑADIR PRODUCTO 
                        </button>
                      </div>
                    </div>

                  <div id="batchDelete" class="custom-datatable">
                      <div class="table-responsive">
                          <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col" sortable="order_id" (sort)="onSort($event)">ID</th>
                                  <th scope="col" sortable="image" (sort)="onSort($event)">Imagen</th>
                                  <th scope="col" sortable="product" (sort)="onSort($event)">Nombre</th>
                                  <th scope="col" sortable="payment_status" (sort)="onSort($event)">Descripcion</th>
                                  <th scope="col" sortable="paymeny_method" (sort)="onSort($event)">Precio</th>
                                </tr>
                              </thead>
                              <tbody >
                              <tr *ngFor="let item of datos">
                                
                                <th scope="row">{{ item.id}}</th>
                                <td>
                                  <img [src]="item.imagen" class="imgTable" style="width: 20px">
                                  </td>
                                <td>{{ item.nombre }}</td>
                                <td>{{ item.descripcion }}</td>
                                <td>{{item.precio}}€</td>
                               
                                  <td>
                                      <a  (click)="verPedido(item)" ><i class='fa fa-eye'></i></a>&nbsp;
                                  </td>
                                  
                              </tr>                              
                              </tbody>
                
                          </table>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- Modal para crear/editar usuario -->
<ng-template #modalUsuario let-modal>
  <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{productoSeleccionado.usuario_id ? 'Editar  Producto' : 'Añadir Producto'}}</h4>
      <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <form>
          <div class="trabajadores-multiselector">
            <angular2-multiselect 
              class="color-black"
              name="workers" 
              [data]="datosCategorias"  
              [(ngModel)]="categoriasSeleccionadas"  
              [settings]="dropdownSettings"> 
          </angular2-multiselect>
          </div>  
          <div class="form-group" style="margin-bottom: 1rem;">
              <label for="nombre">Nombre:</label>
              <input type="text" class="form-control" id="nombre" [(ngModel)]="productoSeleccionado.nombre" name="nombre" maxlength="100">
          </div>

          <div class="form-group" style="margin-bottom: 1rem;">
              <label for="correo">Descripcion:</label>
              <input type="email" class="form-control" id="correo" [(ngModel)]="productoSeleccionado.descripcion" name="correo">
          </div>

          <div class="form-group" style="margin-bottom: 1rem;" >
              <label for="contrasenna">Precio:</label>
              <input type="number" class="form-control" id="precio" [(ngModel)]="productoSeleccionado.precio" name="precio">
          </div>

    
      

      </form>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" [disabled]="!productoSeleccionado.nombre" (click)="modal.close('Save click')">Guardar</button>
  </div>
</ng-template>
