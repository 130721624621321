<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5> Añadir Empleado</h5>
                </div>
                <div class="card-body tab2-card">
                                <form [formGroup]="registerForm">
                                    <div class="mb-3">
                                      <label for="nombre" class="form-label">Nombre</label>
                                      <input type="text" class="form-control" id="nombre" placeholder="Nombre" formControlName="nombre">
                                      <div *ngIf="registerForm.get('nombre').touched && registerForm.get('nombre').invalid" class="text-danger">
                                        <div *ngIf="registerForm.get('nombre').errors?.required">El nombre es requerido</div>
                                        <div *ngIf="registerForm.get('nombre').errors?.minlength">El nombre debe de tener al menos 3 letras</div>
                                      </div>
                                    </div>
                                
                                    <div class="mb-3">
                                      <label for="apellidos" class="form-label">Apellidos</label>
                                      <input type="text" class="form-control" id="apellidos" placeholder="Apellidos" formControlName="apellidos">
                                      <div *ngIf="registerForm.get('apellidos').touched && registerForm.get('apellidos').invalid" class="text-danger">
                                        <div *ngIf="registerForm.get('apellidos').errors?.required">Los apellidos son requeridos</div>
                                        <div *ngIf="registerForm.get('apellidos').errors?.minlength">Los apellidos deben de tener al menos 3 letras</div>
                                      </div>
                                    </div>
                                
                                    <div class="mb-3">
                                      <label for="email" class="form-label">Email</label>
                                      <input type="email" class="form-control" id="email" placeholder="Email" formControlName="email">
                                      <div *ngIf="registerForm.get('email').touched && registerForm.get('email').invalid" class="text-danger">
                                        <div *ngIf="registerForm.get('email').errors?.required">El email es requerido</div>
                                        <div *ngIf="registerForm.get('email').errors?.email">El formato de email es inválido</div>
                                      </div>
                                    </div>
                                    <div class="mb-3">
                                      <label for="telefono" class="form-label">Telefono</label>
                                      <input type="telefono" class="form-control" id="telefono" placeholder="Telefono" formControlName="telefono">
                                      <div *ngIf="registerForm.get('telefono').touched && registerForm.get('telefono').invalid" class="text-danger">
                                        <div *ngIf="registerForm.get('telefono').errors?.required">El telefono es requerido</div>
                                        <div *ngIf="registerForm.get('telefono').errors?.email">El formato de email es inválido</div>
                                      </div>
                                    </div>
                                    <div class="mb-3">
                                      <label for="local" class="form-label">Local</label>
                                      <select class="form-control" id="local" formControlName="local">
                                        <option value="">Seleccione un local</option>
                                        <option value="Almerimar">Almerimar</option>
                                        <option value="Adra">Adra</option>
                                        <option value="Balerma">Balerma</option>
                                      </select>
                                      <div *ngIf="registerForm.get('local').touched && registerForm.get('local').invalid" class="text-danger">
                                        <div *ngIf="registerForm.get('local').errors?.required">El local es requerido</div>
                                      </div>
                                    </div>
                                    
                                    <label for="pass" class="form-label">Contraseña</label>
                                    <div class="input-group">
                                      <span class="input-group-text"><i class="fas fa-lock"></i></span>
                                      <input type="pass" class="form-control" id="pass" placeholder="Contraseña" formControlName="pass">
                                    </div>
                                    <div *ngIf="registerForm.get('pass').touched && registerForm.get('pass').invalid" class="text-danger">
                                      <div *ngIf="registerForm.get('pass').errors?.required">La contraseña es requerida</div>
                                      <div *ngIf="registerForm.get('pass').errors?.minlength">La contraseña debe de tener al menos 6 caracteres</div>
                                    </div>
                                    </form>
                    <div class="pull-right">
                        <button type="button" style="margin-top:10px;" (click)="addNewUser()"  class="btn btn-primary">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->