import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CategoriasService } from 'src/app/shared/service/categorias.service';
import { ProductosService } from 'src/app/shared/service/productos.service';
import { ActivatedRoute ,Router} from '@angular/router';
import { BASE_URL_API } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import ImageCompressor from 'image-compressor.js';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-digital-add',
  templateUrl: './digital-add.component.html',
  styleUrls: ['./digital-add.component.scss']
})


export class DigitalAddComponent implements OnInit {
  public Editor = ClassicEditor;
  nombre: any;
  descripcion: any;
  categoriaSeleccionada: any;
  precio:any;
  oferta: any;
  precio_oferta: any;
  categorias:any;
  imagen: any;
  visible: boolean = true; 
  codigo: any;
  destacado: boolean = false;
  familia: any;
  subfamilia: any;

  constructor(
    private categoriasService: CategoriasService,
    private productosService: ProductosService,
    private router: Router,
    private http: HttpClient
  ) { }

  files: File[] = [];

  onSelect(event): void {
    const file = event.addedFiles[0]; // Tomar solo el primer archivo añadido
    const acceptedFileTypes = ['image/jpeg', 'image/png'];

    if (file && acceptedFileTypes.includes(file.type)) {
      this.compressImage(file);
    } else {
      console.error('Tipo de archivo no permitido.');
    }
  }
  compressImage(file: File): void {
    new ImageCompressor(file, {
        quality: 0.8, // Ajusta la calidad para la compresión
        maxWidth: 1920, // Máximo ancho de la imagen
        maxHeight: 1080, // Máximo alto de la imagen
        success: (compressedFile) => {
            const fileSizeInKB = compressedFile.size / 1024; // Convertir bytes a kilobytes
            console.log(`Tamaño del archivo comprimido: ${fileSizeInKB.toFixed(2)} KB`);

            // Crear el archivo final sin revisar el tamaño
            const finalFile = new File([compressedFile], file.name, {
                type: compressedFile.type,
                lastModified: Date.now()
            });
            this.files = [finalFile]; // Almacenar el archivo final
            console.log(this.files);
            this.uploadFile(finalFile); // Llamar al método para subir el archivo
        },
        error: (e) => {
            console.error('Error durante la compresión de la imagen', e);
        },
    });
}



  uploadFile(file: File): void {
    const formData = new FormData();
    formData.append('image', file, file.name); // Usar 'image' como clave para el FormData

    this.http.post(`${BASE_URL_API}/productos/subir_archivo`, formData).subscribe({
        next: (response: any) => {
            console.log('Archivo subido con éxito', response);
            this.imagen = `${BASE_URL_API}/dist/upload/${response.filename}`; // Actualizar el enlace de la imagen en la aplicación
            console.log(this.imagen);
        },
        error: (error) => {
            console.error('Error al subir el archivo', error);
        }
    });
}

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  ngOnInit() {
    this.getAllCategorias()
  }
  
  onChangeCategoria(newValue: string) {
    this.categoriaSeleccionada = Number(newValue);
    console.log(this.categoriaSeleccionada);
}

  addProducto(){
  // const valorConvertido: string = this.oferta ? "1" : "0";
   let producto = {
    id_empresa: 1,
    imagen: this.imagen,
    codigo: this.codigo,
    nombre : this.nombre,
    categoria_id: this.categoriaSeleccionada,
    familia: this.familia,
    subfamilia: this.subfamilia,
    precio:this.precio,
    en_oferta:this.oferta,
    precio_oferta:this.precio_oferta,
    descripcion: this.descripcion,
    visible: this.visible ? 1 : 0,
    destacado : +this.destacado
    }
    
    this.productosService.addProducto(producto).subscribe({
      next: (response) => {
        console.log('Producto añadido correctamente');
        Swal.fire({
          icon: 'success',
          title: 'Producto añadido',
          text: 'El producto ha sido añadido correctamente.',
          confirmButtonText: 'Aceptar'
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['products/digital/digital-product-list']); // Navega a la lista de productos
          }
        });
      },
      error: (error) => {
        console.error('Error al añadir el producto:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error al añadir el producto',
          text: 'Ha ocurrido un error al intentar añadir el producto. Por favor, inténtelo de nuevo.',
          confirmButtonText: 'Aceptar'
        });
      }
    });
    console.log(producto);
  }

  getAllCategorias(){
    this.categoriasService.getAllCategorias(). subscribe(
      res => {
        this.categorias = res;
        console.log(this.categorias);
      }, 
      error => {
        console.error('Error al añadir worker:', error);
      }
    )
  }

  



}
