import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    const token = localStorage.getItem('auth_app_token');
    if (token) {
      console.log('Authenticated');
      return true;
    } else {
      console.log('Not authenticated, redirecting...');
      this.router.navigate(['/login']);
      return false;
    }
  }
}
