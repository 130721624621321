import { DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { SortEvent } from 'src/app/shared/directives/shorting.directive';
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
import { TableService } from 'src/app/shared/service/table.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-digital-category',
  templateUrl: './digital-category.component.html',
  styleUrls: ['./digital-category.component.scss'],
  providers: [TableService, DecimalPipe],
})
export class DigitalCategoryComponent implements OnInit {
  categoriaFiltro:any=''
  datos:any=[];
  datosUsuario:any=[];
  datosFiltrados:any=[]
  datosCategorias:any;
  categoriasSeleccionadas=[]
  estadoFiltro: string = '';
  provincia: string;
  productoSeleccionado: any;
  user_rol: string;
  constructor(public service: TableService, private modalService: NgbModal,private https: HttpClient, private router: Router,private cdr: ChangeDetectorRef) {
  }
  dropdownSettings = {  
    singleSelection: false,  
    text:"Selecciona Categorias:", 
    selectAllText:'Seleccionar todas', 
    unSelectAllText:'Deseleccionar todas', 
    enableSearchFilter: true, 
    classes:"dropGrupos" 
  };

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    console.log("ddsds");

    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  async ngOnInit() { 
    var t = localStorage.getItem('auth_app_token');
    this.provincia = 'ejido'
    this.user_rol = localStorage.getItem('user_rol');
    if(this.user_rol === 'ejido' || this.user_rol === 'admin'){
      await this.obtenerDatos({token:t});
      await this.obtenerCategorias({token:t})
    }
  }

  async obtenerDatos(body:any): Promise<void> {

    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': 'Bearer '+body.token
      }),
      params:new HttpParams().set('provincia', this.provincia)
    };

    return new Promise((resolve, reject) => {
      this.https.get("https://localhost:3000/productos", httpOptions).subscribe(
        info => {
          this.datos = info;
          console.log(info);
          console.log(this.datos);
          this.cdr.markForCheck();
          resolve();
        },
        err => {
          console.log(err);
          reject();
        }
      );
      this.cdr.markForCheck();
    });

  }

  async obtenerCategorias(body:any): Promise<void> {

    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': 'Bearer '+body.token
      })
    };

    return new Promise((resolve, reject) => {
      this.https.get("https://localhost:3000/categorias", httpOptions).subscribe(
        info => {
          this.datosCategorias = info;
          console.log(info);
        //  console.log(this.datosCategorias);
          this.datosCategorias.forEach(element => {
            element.itemName = element.nombre
          });
          console.log(this.datosCategorias);
          this.cdr.markForCheck();
          resolve();
        },
        err => {
          console.log(err);
          reject();
        }
      );
      this.cdr.markForCheck();
    });

  }

  verPedido(event){
    console.log(event)
    localStorage.setItem('provincia', 'ejido');
    localStorage.setItem('producto_seleccionado',  event.id);
    this.router.navigate(['./verproducto'])
  }

  abrirModalUsuario(content, producto?: any) {
    //this.categoriasSeleccionadas = this.datosCategorias
    this.productoSeleccionado = {...producto} || {} as any;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      if (result) {
        if (this.productoSeleccionado.id) { 
          console.log(this.productoSeleccionado)
          //this.editarProducto(this.productoSeleccionado);
        } else {
          this.crearProducto(this.productoSeleccionado);
        }
      }
    }, (reason) => { });
  }

  
  crearProducto(producto:any){
    
    const categoriasIds: number[] = this.categoriasSeleccionadas.map(categoria => categoria.id);
    console.log(producto);
    const body={
      nombre: producto.nombre,
      descripcion: producto.descripcion,
      precio: producto.precio,
      categorias : categoriasIds
    }
    console.log(body)
    var t = localStorage.getItem('auth_app_token');
    const httpOptions = {
      headers: new HttpHeaders({
        'authorization': 'Bearer '+t
      }),
      params:new HttpParams().set('provincia', this.provincia)
    };

    this.https.post("https://localhost:3000/productos",body, httpOptions).subscribe(
      info => {
        console.log(info)
        location.reload();
      },
      async err => {
        console.log(err);
      }
    );
  }

}
