import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CategoriasService } from 'src/app/shared/service/categorias.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-creaty-category',
  templateUrl: './creaty-category.component.html',
  styleUrls: ['./creaty-category.component.scss']
})
export class CreatyCategoryComponent {
  categoryName: string = '';
  visible : boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    private categoriasService: CategoriasService
  ) {}

  dismiss() {
    this.activeModal.dismiss('cancel');
  }

  save() {
    if (!this.categoryName.trim()) {
      Swal.fire({
        icon: 'warning',
        title: 'Campo vacío',
        text: 'El nombre de la categoría no puede estar vacío.',
        confirmButtonText: 'Aceptar'
      });
      return;
    }
    const categoriaData = {
      nombre: this.categoryName,
      visible: +this.visible  // Incluir el estado visible
    };
    console.log(categoriaData);
    this.categoriasService.addCategoria(categoriaData).subscribe({
      next: (categoria) => {
        Swal.fire({
          icon: 'success',
          title: 'Categoría creada',
          text: 'La categoría se ha creado exitosamente.',
          confirmButtonText: 'Aceptar'
        }).then((result) => {
          if (result.isConfirmed) {
            this.activeModal.close(categoria); // Cierra el modal y devuelve la categoría creada
          }
        });
        console.log('Categoría creada:', categoria);
      },
      error: (error) => {
        console.error('Error al crear categoría:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error al crear',
          text: 'Error al crear la categoría. Intente de nuevo.',
          confirmButtonText: 'Aceptar'
        });
      }
    });
}
}
