import { AuthService } from './../../shared/service/auth.service';
import { DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { SortEvent } from 'src/app/shared/directives/shorting.directive';
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
import { TableService } from 'src/app/shared/service/table.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [TableService, DecimalPipe],
})
export class DashboardComponent implements OnInit {
  categoriaFiltro:any=''
  datosPendientes:any=[];
  datosProceso:any=[];
  datosUsuario:any=[];
  datosFiltrados:any=[]
  datosCategorias:any=[];
  ultimosPedidos:any=[];

  showForm1: boolean = false;
  showForm2: boolean= false;

  user_rol:any;

  constructor( private modalService: NgbModal,private https: HttpClient, private router: Router,private cdr: ChangeDetectorRef, private authService:AuthService ) {
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    console.log("ddsds");

    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
  }

  async ngOnInit() { 
    var t = localStorage.getItem('auth_app_token');
    console.log(t)
    
  }

  navigate(path: string): void {
    this.router.navigate([path]);
  }

   toggleForm1() {
    this.datosPendientes = []
    this.datosProceso =[]
    this.ultimosPedidos =[]
    var t = localStorage.getItem('auth_app_token');
    this.cdr.markForCheck();
  }

   toggleForm2() {
    this.datosPendientes = []
    this.datosProceso =[]
    this.ultimosPedidos =[]
    var t = localStorage.getItem('auth_app_token');
    this.cdr.detectChanges();
  }


  

  verPedido(event){
    console.log(event)
    localStorage.setItem('pedido_seleccionado',  event.id);
    this.router.navigate(['./verpedido'])
  }


}
