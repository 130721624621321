import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { CuponesService } from 'src/app/shared/service/cupones.service';
import { CategoriasService } from 'src/app/shared/service/categorias.service';
import { ProductosService } from 'src/app/shared/service/productos.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-create-coupon',
  templateUrl: './create-coupon.component.html',
  styleUrls: ['./create-coupon.component.scss']
})
export class CreateCouponComponent implements OnInit {
  public model: NgbDateStruct;
  public date: { year: number, month: number };
  public modelFooter: NgbDateStruct;
  public active = 1;
  categoriaSeleccionada: any;
  categorias: any;
  productos: any;
  productoSeleccionado:any;
  detalles: any;
  codigo: any;
  puntos_necesarios:any;
  activo: any;
  fecha_inicio: any;
  couponForm: FormGroup;
  precioProducto: any;

  constructor(
     private calendar: NgbCalendar,
     private fb: FormBuilder,
     private cuponesService: CuponesService,
     private router: Router,
     private categoriasService: CategoriasService,
     private productosService: ProductosService,
     
     ) {
      this.couponForm = this.fb.group({
        producto: ['', Validators.required],
        detalles: ['', Validators.required],
        puntos_necesarios: ['', [Validators.required, Validators.min(1)]],
        activo: [false]
      });
  
  }

selectToday() {
  this.model = this.calendar.getToday();
}
ngOnInit() {
  this.getAllCategorias();
}

onChangeCategoria(newValue: string) {
  this.categoriaSeleccionada = Number(newValue);
  this.loadProductos(this.categoriaSeleccionada);
  console.log(this.categoriaSeleccionada);
}


loadProductos(categoriaId: number) {
  this.productosService.getProductoCategoria(categoriaId).subscribe(
      data => {
          console.log(data); // Imprimir para depuración
          this.productos = data;
      },
      error => {
          console.error('Error al cargar productos:', error);
      }
  );
  console.log(this.productoSeleccionado)
}


onChangeProductoSeleccionado(newValue: string) {
  this.productoSeleccionado = Number(newValue);
  console.log('aqui se pasa el precio'+ this.productoSeleccionado)
  this.productosService.getProductoById(this.productoSeleccionado).subscribe(
    producto => {
      console.log('Producto seleccionado:', producto);
      this.precioProducto = producto.precio; // Asegúrate de que 'precio' es el campo correcto
    },
    error => {
      console.error('Error al obtener detalles del producto:', error);
    }
  );
}


onSubmit() {
  console.log(this.couponForm)
  if (this.couponForm.valid) {
  
    const idProducto = parseInt(this.couponForm.get('producto').value, 10);
    console.log('ID de Producto:', idProducto);

    const productoData = {
      id_producto: idProducto,
      detalles: this.couponForm.get('detalles').value,
      puntos_necesarios: this.couponForm.get('puntos_necesarios').value,
      puntos_requeridos: this.couponForm.get('puntos_necesarios').value,
      activo: this.couponForm.get('activo').value === true ? 1 : 0 
    };
    console.log(productoData)

    this.cuponesService.addCupon(productoData).subscribe({
      next: (response) => {
        console.log('Producto añadido correctamente');
        Swal.fire({
          icon: 'success',
          title: '¡Cupón añadido exitosamente!',
          text: 'El cupón ha sido añadido correctamente.',
          confirmButtonText: 'Aceptar'
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['coupons/list-coupon']); // Redirige a la lista de cupones
          }
        });
      },
      error: (error) => {
        console.error('Error al añadir el cupón:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error al añadir el cupón',
          text: error.error.message || 'No se pudo añadir el cupón.',
          confirmButtonText: 'Aceptar'
        });
      }
    });
  } else {
    Swal.fire({
      icon: 'warning',
      title: 'Datos incompletos',
      text: 'Por favor, asegúrate de que todos los campos estén llenos y sean válidos.',
      confirmButtonText: 'Aceptar'
    });
    console.error('El formulario no es válido');
  }
}

getAllCategorias(){
  this.categoriasService.getAllCategorias(). subscribe(
    res => {
      this.categorias = res;
      console.log(this.categorias);
    }, 
    error => {
      console.error('Error al añadir worker:', error);
    }
  )
}
}
