import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DigitalCategoryComponent } from './digital/digital-category/digital-category.component';
import { DigitalSubCategoryComponent } from './digital/digital-sub-category/digital-sub-category.component';
import { DigitalListComponent } from './digital/digital-list/digital-list.component';
import { DigitalAddComponent } from './digital/digital-add/digital-add.component';
import { CategoriasComponent } from './categorias/categorias.component';
import { EditProductComponent } from './digital/digital-list/edit-product/edit-product.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'digital/digital-category',
        component: DigitalCategoryComponent,
        data: {
          title: "Productos El Ejido",
          breadcrumb: "Productos El Ejido"
        }
      },
      {
        path: 'digital/digital-sub-category',
        component: DigitalSubCategoryComponent,
        data: {
          title: "Ingredientes El Ejido",
          breadcrumb: "Ingredientes El Ejido"
        }
      },
      {
        path: 'digital/digital-product-list',
        component: DigitalListComponent,
        data: {
          title: "Lista de productos",
          breadcrumb: "Lista de productos"
        }
      },
      {
        path: 'digital/digital-product-list/edit',
        component: EditProductComponent,
        data: {
          title: 'Editar producto',
          breadcrumb: 'Editar producto',
        },
      },
      {
        path: 'digital/digital-add-product',
        component: DigitalAddComponent,
        data: {
          title: "Añadir Producto",
          breadcrumb: "Añadir Producto"
        }
      }, 
      {
        path: 'categorias',
        component: CategoriasComponent,
        data: {
          title: "Categorias",
          breadcrumb: "Categorias"
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
